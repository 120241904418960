import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {BreadcrumbService} from '../../../../layout/breadcrumb/breadcrumb.service';
import {AlertService} from '../../../../shared/service/alert.service';

import {ItemsService as GeosService} from '../../../admin/geos';
import {ItemsService} from '../items.service';
import {AuthService} from '../../../../shared/service/auth.service';

@Component({
  selector: 'app-customer-crup',
  templateUrl: './item-crup.component.html'
})
export class ItemCrupComponent implements OnInit, OnDestroy {

  form: FormGroup;
  addressForm: FormGroup;

  stateProvinces: Array<any>;
  counties: Array<any>;

  postalAddressList: Array<any>;
  telecomNumberList: Array<any>;
  selected: any;

  submitted: boolean;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private alertService: AlertService,
    private geosService: GeosService,
    private itemsService: ItemsService,
    public authService: AuthService
  ) {
  }

  ngOnInit() {
    this.breadcrumbService.setRouterLinkBack('/Sales/customers');

    this.form = this.fb.group({
      partyId: new FormControl(''),
      partyName: new FormControl('', Validators.required),
      gender: new FormControl(''),
      birthDate: new FormControl(''),
      contactNumber: new FormControl('', Validators.required),
      emailAddress: new FormControl('')
    });

    this.addressForm = this.fb.group({
      postalContactMechId: new FormControl(''),
      postalContactMechPurposeId: new FormControl('PostalShippingDest'),
      toName: new FormControl('', Validators.required),
      telecomContactMechId: new FormControl(''),
      contactNumber: new FormControl('', Validators.required),
      address1: new FormControl('', Validators.required),
      countyGeoId: new FormControl(''),
      countyGeo: new FormControl(''),
      stateProvinceGeoId: new FormControl(''),
      stateProvinceGeo: new FormControl(''),
      countryGeoId: new FormControl('VNM'),
      isModified: new FormControl(false)
    });

    this.route.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.itemsService.get(params.get('id')).subscribe((response) => {
          if (response.birthDate) {
            response.birthDate = new Date(response.birthDate);
          }
          this.form.patchValue(response);
          this.addressForm.controls.toName.setValue(response.partyName);
        });
        this.itemsService.getContactInfos(params.get('id')).subscribe(({postalAddressList, telecomNumberList}) => {
          this.postalAddressList = new Array<any>();
          if (postalAddressList) {
            postalAddressList.forEach((postalAddress) => {
              this.postalAddressList.push({
                postalContactMechId: postalAddress.postalContactMechId,
                postalContactMechPurposeId: postalAddress.postalContactMechPurposeId,
                toName: postalAddress.postalAddress.toName,
                telecomContactMechId: postalAddress.telecomContactMechId,
                contactNumber: postalAddress.telecomNumber.contactNumber,
                address1: postalAddress.postalAddress.address1,
                countyGeoId: postalAddress.postalAddressCountyGeo ? postalAddress.postalAddressCountyGeo.geoId : '',
                countyGeo: postalAddress.postalAddressCountyGeo ? postalAddress.postalAddressCountyGeo.geoName : '',
                stateProvinceGeoId: postalAddress.postalAddressStateGeo ? postalAddress.postalAddressStateGeo.geoId : '',
                stateProvinceGeo: postalAddress.postalAddressStateGeo ? postalAddress.postalAddressStateGeo.geoName : '',
                postalTrust: postalAddress.postalTrustLevelEnum.description,
                isModified: false
              });
            });
          }
          this.telecomNumberList = new Array<any>();
          if (telecomNumberList) {
            telecomNumberList.forEach((telecomNumber) => {
              this.telecomNumberList.push({
                telecomContactMechId: telecomNumber.telecomContactMechId,
                contactNumber: telecomNumber.telecomNumber.contactNumber
              });
            });
          }
        });
      }
    });

    this.geosService.getList('VNM', 'GEOT_PROVINCE').subscribe(({listData: stateProvinces}) => {
      if (stateProvinces && stateProvinces.length > 0) {
        this.addressForm.controls.stateProvinceGeoId.patchValue(stateProvinces[0]);
        this.loadCounties();
      }
      this.stateProvinces = stateProvinces;
    });
  }

  ngOnDestroy(): void {
    this.breadcrumbService.setRouterLinkBack(null);
  }

  loadCounties() {
    this.counties = new Array<any>();
    if (this.addressForm.controls.stateProvinceGeoId.value && this.addressForm.controls.stateProvinceGeoId.value.toGeoId) {
      this.geosService.getList(this.addressForm.controls.stateProvinceGeoId.value.toGeoId, 'GEOT_COUNTY').subscribe(({listData: counties}) => {
        this.counties = counties;
        if (counties && counties.length > 0) {
          this.addressForm.controls.countyGeoId.patchValue(counties[0]);
        }
      });
    }
  }

  onDropdownContactNumberChange() {
    if (this.addressForm.controls.telecomContactMechId.value && this.addressForm.controls.telecomContactMechId.value.telecomContactMechId) {
      this.addressForm.controls.contactNumber.reset();
      this.addressForm.controls.contactNumber.clearValidators();
      this.addressForm.controls.contactNumber.disable();
    } else {
      this.addressForm.controls.contactNumber.setValidators(Validators.required);
      this.addressForm.controls.contactNumber.enable();
    }
  }

  saveAddressForm() {
    this.addressForm.controls.isModified.setValue(true);

    if (this.addressForm.controls.telecomContactMechId.value && this.addressForm.controls.telecomContactMechId.value.telecomContactMechId) {
      this.addressForm.controls.contactNumber.enable();
      this.addressForm.controls.contactNumber.setValue(this.addressForm.controls.telecomContactMechId.value.contactNumber);
      this.addressForm.controls.telecomContactMechId.setValue(this.addressForm.controls.telecomContactMechId.value.telecomContactMechId);
    }

    const countyGeoId = this.addressForm.controls.countyGeoId.value;
    this.addressForm.controls.countyGeoId.setValue(countyGeoId.toGeoId);
    this.addressForm.controls.countyGeo.setValue(countyGeoId.geoName);
    const stateProvinceGeoId = this.addressForm.controls.stateProvinceGeoId.value;
    this.addressForm.controls.stateProvinceGeoId.setValue(stateProvinceGeoId.toGeoId);
    this.addressForm.controls.stateProvinceGeo.setValue(stateProvinceGeoId.geoName);

    if (this.addressForm.controls.postalContactMechId.value) {
      this.postalAddressList.forEach((postalAddress, index) => {
        if (postalAddress.postalContactMechId === this.addressForm.controls.postalContactMechId.value) {
          this.postalAddressList.splice(index, 1, this.addressForm.value);
        }
      });
    } else {
      this.addressForm.controls.postalContactMechId.setValue(new Date());
      this.addressForm.controls.postalContactMechPurposeId.setValue('PostalShippingDest');
      this.addressForm.controls.countryGeoId.setValue('VNM');
      this.postalAddressList.push(this.addressForm.value);
    }
    this.onRowUnselect();
  }

  onRowSelect() {
    this.addressForm.patchValue(this.selected);
    if (this.addressForm.controls.stateProvinceGeoId.value) {
      this.geosService.getList(this.addressForm.controls.stateProvinceGeoId.value, 'GEOT_COUNTY').subscribe(({listData: counties}) => {
        this.counties = counties;
        if (counties && counties.length > 0) {
          if (this.addressForm.controls.countyGeoId.value) {
            this.addressForm.controls.countyGeoId.patchValue({
              toGeoId: this.addressForm.controls.countyGeoId.value,
              geoName: this.addressForm.controls.countyGeo.value
            });
          } else {
            this.addressForm.controls.countyGeoId.patchValue(counties[0]);
          }
        }
      });
      this.addressForm.controls.stateProvinceGeoId.patchValue({
        toGeoId: this.addressForm.controls.stateProvinceGeoId.value,
        geoName: this.addressForm.controls.stateProvinceGeo.value
      });
    }
  }

  onRowUnselect() {
    this.selected = null;
    this.addressForm.reset();
    this.addressForm.controls.toName.setValue(this.form.controls.partyName.value);
    if (this.stateProvinces && this.stateProvinces.length > 0) {
      this.addressForm.controls.stateProvinceGeoId.patchValue(this.stateProvinces[0]);
      this.loadCounties();
    }
  }

  onSubmit() {
    if (!this.submitted) {
      this.submitted = true;
      const promises = [];
      if (this.form.controls.partyId.value) {
        if (this.form.dirty) {
          promises.push(new Promise((resolve) => {
            this.itemsService.update(this.form.controls.partyId.value, this.form.value).subscribe((response) => {
              resolve(response);
            }, () => {
              resolve();
            });
          }));
        }
        this.postalAddressList.forEach((postalAddress) => {
          if (postalAddress.isModified) {
            if (typeof postalAddress.postalContactMechId === 'string') {
              promises.push(new Promise((resolve) => {
                this.itemsService.updateContactInfos(this.form.controls.partyId.value, postalAddress.postalContactMechId, postalAddress).subscribe((response) => {
                  resolve(response);
                }, () => {
                  resolve();
                });
              }));
            } else {
              delete postalAddress.postalContactMechId;
              promises.push(new Promise((resolve) => {
                this.itemsService.createContactInfos(this.form.controls.partyId.value, postalAddress).subscribe((response) => {
                  resolve(response);
                }, () => {
                  resolve();
                });
              }));
            }
          }
        });
      }
      Promise.all(promises).then(() => {
        this.submitted = false;
        this.onSuccess();
      }, () => {
        this.submitted = false;
        this.onError();
      });
    }
  }

  private onSuccess() {
    this.alertService.onSuccess().then(() => {
      this.router.navigateByUrl('/Sales/customers').then();
    });
  }

  private onError() {
    this.alertService.onError().then();
  }

}
