import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {ItemsService as GeosService} from '../../../admin/geos';
import {ItemsService} from '../items.service';
import {AlertService} from '../../../../shared/service/alert.service';
import {AuthService} from '../../../../shared/service/auth.service';

@Component({
  selector: 'app-dialog-create-customer',
  templateUrl: './dialog-create-customer.component.html'
})
export class DialogCreateCustomerComponent implements OnInit {

  @Input() onSelect: any;

  display: boolean;

  stateProvinces: Array<any>;
  counties: Array<any>;

  form: FormGroup;
  submitted: boolean;

  constructor(
    private fb: FormBuilder,
    private geosService: GeosService,
    private itemsService: ItemsService,
    private alertService: AlertService,
    public authService: AuthService
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      partyId: new FormControl(''),
      partyName: new FormControl('', Validators.required),
      gender: new FormControl(''),
      birthDate: new FormControl(''),
      contactNumber: new FormControl('', Validators.required),
      emailAddress: new FormControl(''),
      address1: new FormControl('', Validators.required),
      countyGeoId: new FormControl(''),
      stateProvinceGeoId: new FormControl(''),
      countryGeoId: new FormControl('VNM')
    });

    this.geosService.getList('VNM', 'GEOT_PROVINCE').subscribe(({listData: stateProvinces}) => {
      if (stateProvinces && stateProvinces.length > 0) {
        this.form.controls.stateProvinceGeoId.patchValue(stateProvinces[0]);
        this.loadCounties();
      }
      this.stateProvinces = stateProvinces;
    });
  }

  initForm() {
    this.form.reset();
    if (this.stateProvinces && this.stateProvinces.length > 0) {
      this.form.controls.stateProvinceGeoId.patchValue(this.stateProvinces[0]);
      this.loadCounties();
    }
  }

  showDialog() {
    this.display = true;
  }

  loadCounties() {
    this.counties = new Array<any>();
    if (this.form.controls.stateProvinceGeoId.value && this.form.controls.stateProvinceGeoId.value.toGeoId) {
      this.geosService.getList(this.form.controls.stateProvinceGeoId.value.toGeoId, 'GEOT_COUNTY').subscribe(({listData: counties}) => {
        this.counties = counties;
        if (counties && counties.length > 0) {
          this.form.controls.countyGeoId.patchValue(counties[0]);
        }
      });
    }
  }

  onSubmit() {
    if (!this.submitted) {
      this.submitted = true;
      this.form.controls.countryGeoId.setValue('VNM');
      this.itemsService.create(Object.assign(this.form.value, {
        stateProvinceGeoId: this.form.controls.stateProvinceGeoId.value.toGeoId,
        countyGeoId: this.form.controls.countyGeoId.value.toGeoId
      })).subscribe((response) => {
        this.submitted = false;
        if (response.partyId) {
          this.onSuccess();
          this.onSelect({partyId: response.partyId, pseudoId: response.partyId, partyName: this.form.controls.partyName.value});

          this.initForm();
        } else {
          this.onError();
        }
      }, () => {
        this.submitted = false;
      });
    }
  }

  private onSuccess() {
    this.alertService.onSuccess().then(() => {
      this.display = false;
    });
  }

  private onError() {
    this.alertService.onError().then();
  }
}
