export class ModuleMenuModel {
  constructor(
    public id?: string,
    public routerLink?: string,
    public icon?: string,
    public title?: string,
    public children?: ModuleMenuModel[],
    public status: boolean = true
  ) {
  }
}
