import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private readonly url = 'mag-erp-admin/orders';

  constructor(private http: HttpClient) {
  }

  getHeader(orderId: string): Observable<any> {
    return this.http.get(`${this.url}/${orderId}`);
  }

  approve(orderId: string): Observable<any> {
    return this.http.patch(`${this.url}/${orderId}/approve`, null);
  }

  send(orderId: string): Observable<any> {
    return this.http.patch(`${this.url}/${orderId}/send`, null);
  }

  close(orderId: string): Observable<any> {
    return this.http.patch(`${this.url}/${orderId}/close`, null);
  }

  proposed(orderId: string): Observable<any> {
    return this.http.patch(`${this.url}/${orderId}/proposed`, null);
  }

  place(orderId: string): Observable<any> {
    return this.http.patch(`${this.url}/${orderId}/place`, null);
  }

  getStatusHistories(orderId: string): Observable<any> {
    return this.http.get(`${this.url}/${orderId}/statusHistories`);
  }

  getParts(orderId: string): Observable<any> {
    return this.http.get(`${this.url}/${orderId}/parts`);
  }

  updatePart(orderId: string, orderPartSeqId: string, item: any): Observable<any> {
    return this.http.put(`${this.url}/${orderId}/parts/${orderPartSeqId}`, item);
  }

  patchPart(orderId: string, orderPartSeqId: string, item: any): Observable<any> {
    return this.http.patch(`${this.url}/${orderId}/parts/${orderPartSeqId}`, item);
  }

  setBillingShippingInfo(orderId: string, orderPartSeqId: string, item: any): Observable<any> {
    return this.http.put(`${this.url}/${orderId}/parts/${orderPartSeqId}/billingShippingInfo`, item);
  }

  getItems(orderId: string, orderPartSeqId: string): Observable<any> {
    return this.http.get(`${this.url}/${orderId}/parts/${orderPartSeqId}/items`);
  }

  ship(orderId: string, orderPartSeqId: string): Observable<any> {
    return this.http.post(`${this.url}/${orderId}/parts/${orderPartSeqId}/shipment/ship`, {});
  }

  shipParts(orderId: string, orderPartSeqIds: Array<string>): Observable<any> {
    return this.http.post(`${this.url}/${orderId}/parts/ship`, {orderPartSeqIds});
  }

  packItem(orderId: string, orderPartSeqId: string, orderItemSeqId: string): Observable<any> {
    return this.http.post(`${this.url}/${orderId}/parts/${orderPartSeqId}/items/${orderItemSeqId}/pack`, {});
  }

  forcePackItem(orderId: string, orderPartSeqId: string, orderItemSeqId: string): Observable<any> {
    return this.http.post(`${this.url}/${orderId}/parts/${orderPartSeqId}/items/${orderItemSeqId}/force-pack`, {});
  }

  createItem(orderId: string, orderPartSeqId: string, orderItem: any): Observable<any> {
    if (orderPartSeqId) {
      return this.http.post(`${this.url}/${orderId}/parts/${orderPartSeqId}/items`, orderItem);
    } else {
      return this.http.post(`${this.url}/${orderId}/items`, orderItem);
    }
  }

  updateItem(orderId: string, orderPartSeqId: string, orderItemSeqId: string, orderItem: any): Observable<any> {
    return this.http.put(`${this.url}/${orderId}/parts/${orderPartSeqId}/items/${orderItemSeqId}`, orderItem);
  }

  deleteItem(orderId: string, orderPartSeqId: string, orderItemSeqId: string): Observable<any> {
    return this.http.delete(`${this.url}/${orderId}/parts/${orderPartSeqId}/items/${orderItemSeqId}`);
  }

  createShipment(orderId: string, orderPartSeqId: string): Observable<any> {
    return this.http.post(`${this.url}/${orderId}/parts/${orderPartSeqId}/shipment`, {});
  }

  getShipment(orderId: string, orderPartSeqId: string): Observable<any> {
    return this.http.get(`${this.url}/${orderId}/parts/${orderPartSeqId}/shipment`);
  }

  getFee(orderId: string): Observable<any> {
    return this.http.get(`${this.url}/${orderId}/fee-items`);
  }

  createFee(orderId: string, item): Observable<any> {
    return this.http.post(`${this.url}/${orderId}/fee-items`, item);
  }

  updateFee(orderId: string, orderItemSeqId, item): Observable<any> {
    return this.http.put(`${this.url}/${orderId}/fee-items/${orderItemSeqId}`, item);
  }

}
