export default {
  Oops: {
    en: 'Oops...',
    vi: 'Có lỗi xảy ra...'
  },
  ServiceUnavailable: {
    en: 'Service Unavailable',
    vi: 'Lỗi kết nối, vui lòng thử lại sau'
  },
  Unauthorized: {
    en: '401 Unauthorized',
    vi: 'Phiên đăng nhập đã hết hạn'
  },
  Forbidden: {
    en: '403 Forbidden',
    vi: 'Bạn không có quyền truy cập'
  },
  NotFound: {
    en: '404 Not Found',
    vi: 'Dữ liệu không tồn tại'
  },
  SignIn: {
    en: 'Sign in',
    vi: 'Đăng nhập'
  },
  Logout: {
    en: 'Logout',
    vi: 'Đăng xuất'
  },
  ForgotPassword: {
    en: 'Forgot password?',
    vi: 'Quên mật khẩu?'
  },
  NeedHelp: {
    en: 'Need help?',
    vi: 'Cần hỗ trợ?'
  },
  Retry: {
    en: 'Retry',
    vi: 'Thử lại'
  },
  UsernameOrPasswordIncorrect: {
    en: 'Username Or Password Incorrect',
    vi: 'Tên đăng nhập hoặc mật khẩu không chính xác!'
  },
  Success: {
    en: 'Success',
    vi: 'Thành công!'
  },
  Home: {
    en: 'Home',
    vi: 'Trang chủ'
  },
  Sales: {
    en: 'Sales',
    vi: 'Bán hàng'
  },
  Purchases: {
    en: 'Purchases',
    vi: 'Mua hàng'
  },
  ThePurchases: {
    en: 'Purchases',
    vi: 'Tổng mua'
  },
  Logistics: {
    en: 'Logistics',
    vi: 'Kho vận'
  },
  Profile: {
    en: 'Profile',
    vi: 'Thông tin cá nhân'
  },
  Dashboard: {
    en: 'Dashboard',
    vi: 'Tổng quan'
  },
  Yes: {
    en: 'Yes',
    vi: 'Có'
  },
  No: {
    en: 'No',
    vi: 'Không'
  },
  CreateOrder: {
    en: 'Create Order',
    vi: 'Tạo đơn hàng'
  },
  Order: {
    en: 'Order',
    vi: 'Đơn hàng'
  },
  Customer: {
    en: 'Customer',
    vi: 'Khách hàng'
  },
  PaidByCustomer: {
    en: 'Paid By Customer',
    vi: 'Khách hàng trả'
  },
  Cogs: {
    en: 'cogs',
    vi: 'Giá vốn'
  },
  SalesOrders: {
    en: 'Sales Orders',
    vi: 'Đơn hàng bán'
  },
  PurchasesOrders: {
    en: 'Purchases Orders',
    vi: 'Đơn hàng mua'
  },
  ReturnsOrders: {
    en: 'Returns Orders',
    vi: 'Đơn hàng hoàn'
  },
  Report: {
    en: 'Report',
    vi: 'Báo cáo'
  },
  Revenue: {
    en: 'Revenue',
    vi: 'Doanh thu'
  },
  ProfitMargin: {
    en: 'Profit Margin',
    vi: 'Tỷ suất lợi nhuận'
  },
  TheSales: {
    en: 'Sales',
    vi: 'Doanh số'
  },
  Product: {
    en: 'Product',
    vi: 'Sản phẩm'
  },
  SupplierId: {
    en: 'SupplierId',
    vi: 'Mã nhà cung cấp'
  },
  Supplier: {
    en: 'Supplier',
    vi: 'Nhà cung cấp'
  },
  TransferShipment: {
    en: 'Transfer Shipment',
    vi: 'Điều chuyển'
  },
  InventoryAssets: {
    en: 'Inventory Assets',
    vi: 'Tồn kho'
  },
  PhysicalChanges: {
    en: 'Physical Changes',
    vi: 'Thay đổi vật lí'
  },
  Reason: {
    en: 'Reason',
    vi: 'Nguyên nhân'
  },
  quantityChange: {
    en: 'Quantity Change',
    vi: 'SL thay đổi'
  },
  comments: {
    en: 'comments',
    vi: 'Ghi chú'
  },
  Back: {
    en: 'Back',
    vi: 'Trở lại'
  },
  Detail: {
    en: 'Detail',
    vi: 'Chi tiết'
  },
  Add: {
    en: 'Add',
    vi: 'Thêm'
  },
  Save: {
    en: 'Save',
    vi: 'Lưu'
  },
  Close: {
    en: 'Close',
    vi: 'Đóng'
  },
  Begin: {
    en: 'Begin',
    vi: 'Bắt đầu'
  },
  Cancel: {
    en: 'Cancel',
    vi: 'Hủy bỏ'
  },
  Create: {
    en: 'Create',
    vi: 'Tạo mới'
  },
  CreateAndContinue: {
    en: 'Create And Continue',
    vi: 'Tạo mới và tiếp tục'
  },
  Edit: {
    en: 'Edit',
    vi: 'Chỉnh sửa'
  },
  Update: {
    en: 'Update',
    vi: 'Cập nhật'
  },
  Delete: {
    en: 'Delete',
    vi: 'Xóa'
  },
  Lock: {
    en: 'Lock',
    vi: 'Khóa'
  },
  Unlock: {
    en: 'Unlock',
    vi: 'Mở khóa'
  },
  productId: {
    en: 'Product Id',
    vi: 'Mã sản phẩm'
  },
  productName: {
    en: 'Product Name',
    vi: 'Tên sản phẩm'
  },
  description: {
    en: 'Description',
    vi: 'Mô tả'
  },
  status: {
    en: 'Status',
    vi: 'Trạng thái'
  },
  tikiStatus: {
    en: 'Tiki Status',
    vi: 'Trạng thái Tiki'
  },
  ReturnStatus: {
    en: 'Return Status',
    vi: 'Trạng thái hoàn'
  },
  FieldIsRequired: {
    en: 'Field is required.',
    vi: 'Trường không được để trống.'
  },
  ContactNumberInvalid: {
    en: 'Contact number invalid.',
    vi: 'Số điện thoại không hợp lệ.'
  },
  Search: {
    en: 'Search',
    vi: 'Tìm kiếm'
  },
  SearchProduct: {
    en: 'Search Product',
    vi: 'Tìm sản phẩm'
  },
  SearchCustomer: {
    en: 'Search Customer',
    vi: 'Tìm khách hàng'
  },
  unitListPrice: {
    en: 'Unit List Price',
    vi: 'Giá niêm yết'
  },
  unitAmount: {
    en: 'Unit Amount',
    vi: 'Giá hiện tại'
  },
  qty: {
    en: 'Qty',
    vi: 'SL'
  },
  quantity: {
    en: 'Quantity',
    vi: 'Số lượng'
  },
  quantityNotBilled: {
    en: 'Not Billed',
    vi: 'Chưa thanh toán'
  },
  quantityNotShipped: {
    en: 'Not Shipped',
    vi: 'Chưa giao'
  },
  itemTotal: {
    en: 'Item Total',
    vi: 'Thành tiền'
  },
  Information: {
    en: 'Information',
    vi: 'Thông tin'
  },
  facility: {
    en: 'Facility',
    vi: 'Kho hàng'
  },
  FacilityType: {
    en: 'Facility Type',
    vi: 'Loại kho'
  },
  ProductStore: {
    en: 'Product Store',
    vi: 'Cửa hàng'
  },
  storeName: {
    en: 'Store name',
    vi: 'Tên cửa hàng'
  },
  quantityOnHandTotal: {
    en: 'QOH',
    vi: 'QOH'
  },
  QOHDiff: {
    en: 'QOH Diff',
    vi: 'QOH thay đổi'
  },
  availableToPromiseTotal: {
    en: 'ATP',
    vi: 'ATP'
  },
  ATPDiff: {
    en: 'ATP Diff',
    vi: 'ATP thay đổi'
  },
  NotAvailable: {
    en: 'Not Available',
    vi: 'Không có sẵn'
  },
  NotIssued: {
    en: 'Not Issued',
    vi: 'Chưa xuất'
  },
  Source: {
    en: 'Source',
    vi: 'Nguồn'
  },
  Total: {
    en: 'Total',
    vi: 'Tổng'
  },
  grandTotal: {
    en: 'Grand Total',
    vi: 'Tổng cộng'
  },
  feeTotal: {
    en: 'Fee Total',
    vi: 'Tổng phí'
  },
  fee: {
    en: 'Fee',
    vi: 'Phí'
  },
  Profit: {
    en: 'Profit',
    vi: 'Lợi nhuận'
  },
  quantityTotal: {
    en: 'Quantity Total',
    vi: 'Tổng số'
  },
  pseudoId: {
    en: 'Pseudo Id',
    vi: 'Mã'
  },
  placedDate: {
    en: 'Placed Date',
    vi: 'Ngày đặt'
  },
  createdDate: {
    en: 'Created Date',
    vi: 'Ngày tạo'
  },
  facilityName: {
    en: 'Facility Name',
    vi: 'Tên kho'
  },
  Geo: {
    en: 'Geo',
    vi: 'Địa phương'
  },
  Address: {
    en: 'Address',
    vi: 'Địa chỉ'
  },
  sequenceNum: {
    en: 'SequenceNum',
    vi: 'Số thứ tự'
  },
  SupplierNotFound: {
    en: 'Supplier Not Found',
    vi: 'Không tìm thấy nhà cung cấp'
  },
  PriceNotFound: {
    en: 'Price Not Found',
    vi: 'Không tìm thấy giá sản phẩm'
  },
  ProductNotFound: {
    en: 'Product Not Found',
    vi: 'Không tìm thấy sản phẩm'
  },
  Picklist: {
    en: 'Picklist',
    vi: 'Soạn hàng'
  },
  OutgoingShipment: {
    en: 'Outgoing Shipment',
    vi: 'Xuất hàng'
  },
  IncomingShipment: {
    en: 'Incoming Shipment',
    vi: 'Nhập hàng'
  },
  Content: {
    en: 'Content',
    vi: 'Nội dung'
  },
  Associated: {
    en: 'Associated',
    vi: 'Liên kết'
  },
  Categories: {
    en: 'Categories',
    vi: 'Danh mục'
  },
  Price: {
    en: 'Price',
    vi: 'Giá'
  },
  Identification: {
    en: 'Identification',
    vi: 'Mã vạch'
  },
  Features: {
    en: 'Features',
    vi: 'Đặc điểm'
  },
  Brand: {
    en: 'Brand',
    vi: 'Thương hiệu'
  },
  ProductImage: {
    en: 'Product Image',
    vi: 'Ảnh sản phẩm'
  },
  ProductImageDetail: {
    en: 'Product Image Detail',
    vi: 'Album ảnh sản phẩm'
  },
  Placed: {
    en: 'Placed',
    vi: 'Đã lập'
  },
  Shipped: {
    en: 'Shipped',
    vi: 'Đã vận chuyển'
  },
  Delivered: {
    en: 'Delivered',
    vi: 'Đã giao'
  },
  Approved: {
    en: 'Approved',
    vi: 'Đã duyệt'
  },
  Approve: {
    en: 'Approve',
    vi: 'Duyệt'
  },
  UnApprove: {
    en: 'UnApprove',
    vi: 'Hủy duyệt'
  },
  Sent: {
    en: 'Sent',
    vi: 'Đã gửi'
  },
  Completed: {
    en: 'Completed',
    vi: 'Hoàn thành'
  },
  DateTime: {
    en: 'DateTime',
    vi: 'Thời gian'
  },
  Employee: {
    en: 'Employee',
    vi: 'Nhân viên'
  },
  OriginFacility: {
    en: 'Origin Facility',
    vi: 'Kho chuyển'
  },
  DestinationFacility: {
    en: 'Destination Facility',
    vi: 'Kho nhận'
  },
  Phone: {
    en: 'Phone',
    vi: 'Số điện thoại'
  },
  Email: {
    en: 'Email',
    vi: 'Email'
  },
  CustomerClass: {
    en: 'Customer Class',
    vi: 'Loại khách hàng'
  },
  FullName: {
    en: 'Full Name',
    vi: 'Họ và tên'
  },
  birthDate: {
    en: 'BirthDate',
    vi: 'Sinh nhật'
  },
  gender: {
    en: 'Gender',
    vi: 'Giới tính'
  },
  ShippingAddress: {
    en: 'Shipping Address',
    vi: 'Địa chỉ giao hàng'
  },
  address1: {
    en: 'Address',
    vi: 'Số nhà, đường'
  },
  stateProvinceGeoId: {
    en: 'State Province',
    vi: 'Tỉnh/thành phố'
  },
  countyGeoId: {
    en: 'County',
    vi: 'Quận/huyện'
  },
  PostalTrust: {
    en: 'Postal Trust',
    vi: 'Tin cậy'
  },
  toName: {
    en: 'To Name',
    vi: 'Người nhận'
  },
  Select: {
    en: 'Select',
    vi: 'Chọn'
  },
  receivedDate: {
    en: 'Received Date',
    vi: 'Ngày nhận'
  },
  pickupDate: {
    en: 'Pickup Date',
    vi: 'Ngày lấy hàng'
  },
  FromShipment: {
    en: 'From Shipment',
    vi: 'Từ mã nhập'
  },
  FromOrder: {
    en: 'From Order',
    vi: 'Từ đơn mua'
  },
  FromReturn: {
    en: 'From Return',
    vi: 'Từ đơn hoàn'
  },
  acquireCost: {
    en: 'Acquire Cost',
    vi: 'Chi phí mua'
  },
  Reservation: {
    en: 'Reservation',
    vi: 'Gom hàng'
  },
  Date: {
    en: 'Date',
    vi: 'Ngày'
  },
  estimatedReadyDate: {
    en: 'Estimated Ready Date',
    vi: 'Ngày dự kiến'
  },
  AssetDetailHistory: {
    en: 'Asset Detail History',
    vi: 'Lịch sử xuất nhập'
  },
  Receipt: {
    en: 'Receipt',
    vi: 'Phiếu nhập'
  },
  Issuance: {
    en: 'Issuance',
    vi: 'Phiếu xuất'
  },
  ReservationRemoved: {
    en: 'Reservation Removed',
    vi: 'Gom hàng đã hủy'
  },
  MoveFromAsset: {
    en: 'Move From Asset',
    vi: 'Chuyển từ tồn kho'
  },
  ConsumerPrices: {
    en: 'Consumer Prices',
    vi: 'Giá bán'
  },
  SupplierPrices: {
    en: 'Supplier Prices',
    vi: 'Giá mua'
  },
  ShipMethod: {
    en: 'Ship Method',
    vi: 'PT giao hàng'
  },
  Ship: {
    en: 'Ship',
    vi: 'Giao hàng'
  },
  SalesChannel: {
    en: 'Sales Channel',
    vi: 'Kênh bán hàng'
  },
  Confirm: {
    en: 'Confirm',
    vi: 'Xác nhận'
  },
  WaitForConfirm: {
    en: 'Wait for confirm',
    vi: 'Cần xác nhận'
  },
  PrintDeliveryNote: {
    en: 'Print delivery note',
    vi: 'In phiếu giao hàng'
  },
  OrderId: {
    en: 'Order Id',
    vi: 'Mã đơn hàng'
  },
  externalId: {
    en: 'External Id',
    vi: 'Mã của sàn'
  },
  SendOrReturnItems: {
    en: 'Send / Return items',
    vi: 'Gửi / trả hàng'
  },
  ConfirmationDate: {
    en: 'Confirmation Date',
    vi: 'Ngày xác nhận'
  },
  InventoryRequisitionId: {
    en: 'Inventory Requisition Id',
    vi: 'Phiếu gửi'
  },
  Print: {
    en: 'Print',
    vi: 'In'
  },
  MergePage: {
    en: 'Merge Page',
    vi: 'Gộp trang'
  },
  PrintMinCode: {
    en: 'Print MinCode',
    vi: 'In MinCode'
  },
  PrintOrder: {
    en: 'Print Order',
    vi: 'In đơn'
  },
  PrintSelectedOrders: {
    en: 'Print Selected Orders',
    vi: 'In đơn đã chọn'
  },
  PrintNotPrintedYet: {
    en: 'Print Not Printed Yet',
    vi: 'In tất cả đơn chưa in'
  },
  AllOrdersArePrinted: {
    en: 'All Orders Are Printed',
    vi: 'Tất cả đơn đã in'
  },
  IsPrinted: {
    en: 'Is Printed',
    vi: 'Đã in'
  },
  Deadline: {
    en: 'Deadline',
    vi: 'Hạn chót'
  },
  ConfirmSelectedOrders: {
    en: 'Confirm Selected Orders',
    vi: 'Xác nhận đơn đã chọn'
  },
  ReceiveSelectedOrders: {
    en: 'Receive Selected Orders',
    vi: 'Nhận đơn đã chọn'
  },
  ConfirmAll: {
    en: 'Confirm All',
    vi: 'Xác nhận tất cả'
  },
  AllOrdersAreConfirmed: {
    en: 'All Orders Are Confirmed',
    vi: 'Tất cả đơn đã Xác nhận'
  },
  Organization: {
    en: 'Organization',
    vi: 'Tổ chức'
  },
  Synchronization: {
    en: 'Synchronization',
    vi: 'Đồng bộ'
  },
  Sync: {
    en: 'Sync',
    vi: 'Đồng bộ'
  },
  SyncSelectedOrders: {
    en: 'Sync Selected Orders',
    vi: 'Đồng bộ đơn đã chọn'
  },
  Username: {
    en: 'Username',
    vi: 'Tài khoản'
  },
  Password: {
    en: 'Password',
    vi: 'Mật khẩu'
  },
  IncludeFacility: {
    en: 'Include Facility',
    vi: 'Bao gồm kho hàng'
  },
  newPassword: {
    en: 'New Password',
    vi: 'Mật khẩu mới'
  },
  newPasswordVerify: {
    en: 'New Password Verify',
    vi: 'Nhập lại mật khẩu mới'
  },
  ChangePassword: {
    en: 'Change Password',
    vi: 'Đổi mật khẩu'
  },
  cronExpressionSync: {
    en: 'Cron Expression Sync',
    vi: 'Chu kỳ đồng bộ'
  },
  cronExpressionConfirm: {
    en: 'Cron Expression Confirm',
    vi: 'Chu kỳ xác nhận'
  },
  OnlyNotPrinted: {
    en: 'Only Not Printed',
    vi: 'Chỉ hiện các đơn chưa in'
  },
  All: {
    en: 'All',
    vi: 'Tất cả'
  },
  InPeriod: {
    en: 'In Period',
    vi: 'Trong khoảng thời gian'
  },
  AllTime: {
    en: 'All time',
    vi: 'Toàn thời gian'
  },
  Last30days: {
    en: 'Last 30 days',
    vi: '30 ngày qua'
  },
  Last7days: {
    en: 'Last 7 days',
    vi: '7 ngày qua'
  },
  Today: {
    en: 'Today',
    vi: 'Hôm nay'
  },
  ThisMonth: {
    en: 'This month',
    vi: 'Tháng này'
  },
  ProductError: {
    en: 'Product Error',
    vi: 'Sản phẩm lỗi'
  },
  MatchingProduct: {
    en: 'Matching Product',
    vi: 'Gán sản phẩm'
  },
  CreateComboProduct: {
    en: 'Create Combo Product',
    vi: 'Tạo Combo'
  },
  ComboInvalid: {
    en: 'Combo Invalid',
    vi: 'Combo không hợp lệ'
  },
  isCombo: {
    en: 'Is Combo',
    vi: 'Là SP Combo'
  },
  Seller: {
    en: 'Seller',
    vi: 'Nhà bán hàng'
  },
  Ordered: {
    en: 'Ordered',
    vi: 'Đã đặt'
  },
  Received: {
    en: 'Received',
    vi: 'Đã nhận'
  },
  Scheduled: {
    en: 'Scheduled',
    vi: 'Lên kế hoạch'
  },
  Receive: {
    en: 'Receive',
    vi: 'Nhận'
  },
  Issued: {
    en: 'Issued',
    vi: 'Đã xuất'
  },
  Issue: {
    en: 'Issue',
    vi: 'Xuất'
  },
  ForceIssue: {
    en: 'Force Issue',
    vi: 'Vẫn xuất'
  },
  ReceiveEntire: {
    en: 'Receive Entire',
    vi: 'Nhận toàn bộ'
  },
  PackEntire: {
    en: 'Pack Entire',
    vi: 'Xuất toàn bộ'
  },
  quantityAcceptedTotal: {
    en: 'Quantity Accepted',
    vi: 'SL đã nhận'
  },
  quantityRemaining: {
    en: 'Quantity Remaining',
    vi: 'SL còn lại'
  },
  quantityPackedOrIssued: {
    en: 'Quantity PackedOrIssued',
    vi: 'SL đã xuất'
  },
  minQuantity: {
    en: 'Min Quantity',
    vi: 'SL tối thiểu'
  },
  damagedQuantity: {
    en: 'Damaged Quantity',
    vi: 'SL hỏng'
  },
  fromDate: {
    en: 'From Date',
    vi: 'Từ ngày'
  },
  thruDate: {
    en: 'Thru Date',
    vi: 'Đến ngày'
  },
  NotPacked: {
    en: 'Not packed',
    vi: 'Chưa đóng hàng'
  },
  Packed: {
    en: 'Packed',
    vi: 'Đã đóng hàng'
  },
  Requisitions: {
    en: 'Requisitions',
    vi: 'Phiếu gửi'
  },
  Code: {
    en: 'Code',
    vi: 'Mã'
  },
  RefusedRequisitions: {
    en: 'Refused Requisitions',
    vi: 'Phiếu gửi bị từ chối'
  },
  Withdrawals: {
    en: 'Withdrawals',
    vi: 'Phiếu nhận'
  },
  Returns: {
    en: 'Returns',
    vi: 'Hàng hoàn'
  },
  expectedQuantity: {
    en: 'Expected',
    vi: 'Đặt'
  },
  receivedQuantity: {
    en: 'Received',
    vi: 'Nhận'
  },
  actualQuantity: {
    en: 'Actual Quantity',
    vi: 'SL thực tế'
  },
  acceptedQuantity: {
    en: 'Accepted',
    vi: 'Chấp nhận'
  },
  rejectedQuantity: {
    en: 'Rejected',
    vi: 'Từ chối'
  },
  note: {
    en: 'note',
    vi: 'Ghi chú'
  },
  ReturnableOrder: {
    en: 'Returnable Order',
    vi: 'Đơn hàng có thể trả lại'
  },
  ReturnResponse: {
    en: 'Return Response',
    vi: 'Đền bù'
  },
  returnPrice: {
    en: 'Return Price',
    vi: 'Tiền hoàn'
  },
  inventoryNotAvailable: {
    en: 'Inventory NA',
    vi: 'Thiếu hàng'
  },
  TypeEnum: {
    en: 'Type Enum',
    vi: 'Loại'
  },
  AutoSubmit: {
    en: 'Auto Submit',
    vi: 'Tự động xác nhận'
  },
  History: {
    en: 'History',
    vi: 'Lịch sử'
  },
  Service: {
    en: 'Service',
    vi: 'Dịch vụ'
  },
  Waiting: {
    en: 'Waiting',
    vi: 'Đang chờ'
  },
  Accounting: {
    en: 'Accounting',
    vi: 'Kế toán'
  },
  Finance: {
    en: 'Finance',
    vi: 'Tài chính'
  },
  Payment: {
    en: 'Payment',
    vi: 'Thanh toán'
  },
  PaymentInstrument: {
    en: 'Payment Instrument',
    vi: 'PT Thanh toán'
  },
  Paid: {
    en: 'Paid',
    vi: 'Đã thanh toán'
  },
  Unpaid: {
    en: 'Unpaid',
    vi: 'Chưa thanh toán'
  },
  invoiceId: {
    en: 'Invoice Id',
    vi: 'Mã hóa đơn'
  },
  InvoiceNumber: {
    en: 'Invoice Number',
    vi: 'Số hóa đơn'
  },
  grossTotal: {
    en: 'Gross Total',
    vi: 'Số tiền'
  },
  invoiceTotal: {
    en: 'Invoice Total',
    vi: 'Số tiền'
  },
  Amount: {
    en: 'Amount',
    vi: 'Số tiền'
  },
  income: {
    en: 'Income',
    vi: 'Nhận về'
  },
  unpaidTotal: {
    en: 'Unpaid Total',
    vi: 'Còn thiếu'
  },
  statementCode: {
    en: 'Statement Code',
    vi: 'Mã sao kê'
  },
  statementDate: {
    en: 'Statement Date',
    vi: 'Ngày sao kê'
  },
  Download: {
    en: 'Download',
    vi: 'Tải xuống'
  },
  LabelPrinting: {
    en: 'Label Printing',
    vi: 'Tem phụ'
  },
  LostGoods: {
    en: 'Lost Goods',
    vi: 'Hàng thất lạc'
  },
  Diff: {
    en: 'Diff',
    vi: 'Chênh lệch'
  },
  List: {
    en: 'List',
    vi: 'Danh sách'
  },
  SellRate: {
    en: 'SellRate',
    vi: 'Tốc độ bán'
  },
  ProductDimension: {
    en: 'Product Dimension',
    vi: 'Kích thước SP'
  },
  Height: {
    en: 'Height',
    vi: 'Cao'
  },
  Width: {
    en: 'Width',
    vi: 'Rộng'
  },
  Depth: {
    en: 'Depth',
    vi: 'Sâu'
  },
  Weight: {
    en: 'Weight',
    vi: 'Nặng'
  },
  Pull: {
    en: 'Pull',
    vi: 'Đồng bộ về'
  },
  Push: {
    en: 'Push',
    vi: 'Đồng bộ lên'
  },
  AccountType: {
    en: 'Account Type',
    vi: 'Loại tài khoản'
  },
  ShippingFeeCustomer: {
    en: 'Shipping Fee Customer',
    vi: 'Phí vận chuyển KH'
  },
  ShippingFee: {
    en: 'Shipping Fee',
    vi: 'Phí vận chuyển'
  },
  Plan: {
    en: 'Plan',
    vi: 'Kế hoạch'
  },
  Average: {
    en: 'Average',
    vi: 'Trung bình'
  },
  Overviews: {
    en: 'Overviews',
    vi: 'Tổng quan'
  },
  Wallet: {
    en: 'Wallet',
    vi: 'Số dư ví'
  },
  TrackingNumber: {
    en: 'Tracking Number',
    vi: 'Mã vận đơn'
  },
  SupplierLiabilities: {
    en: 'Supplier Liabilities',
    vi: 'Công nợ NCC'
  },
  Summary: {
    en: 'Summary',
    vi: 'Tổng hợp'
  },
  Voucher: {
    en: 'Voucher',
    vi: 'MÃ GG'
  },
  Minimum: {
    en: 'Minimum',
    vi: 'Tối thiểu'
  },
  MinimumStock: {
    en: 'Minimum Stock',
    vi: 'Tồn kho tối thiểu'
  },
  QuantityInTransfer: {
    en: 'In Transfer',
    vi: 'SL đang chuyển'
  },
  Privileges: {
    en: 'Privileges',
    vi: 'Phân quyền'
  },
  Screen: {
    en: 'Screen',
    vi: 'Màn hình'
  },
  Permission: {
    en: 'Permission',
    vi: 'Quyền'
  },
  Grant: {
    en: 'Grant',
    vi: 'Cho phép'
  },
  StockTake: {
    en: 'Stock take',
    vi: 'Kiểm kho'
  },
};
