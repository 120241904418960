import {Component, Input, OnInit} from '@angular/core';

import {UserService} from '../../service/user.service';

import {ItemsService as FacilitiesService} from '../../../entities/admin/facilities';

@Component({
  selector: 'app-select-facility',
  templateUrl: './select-facility.component.html'
})
export class SelectFacilityComponent implements OnInit {

  @Input() getAll: boolean;
  @Input() ownerPartyId: string;
  @Input() onChange: any;

  item: any;
  items: Array<any>;

  constructor(private facilitiesService: FacilitiesService) {
  }

  ngOnInit() {
    let event: any;
    event = {
      filters: {
        statusId: {value: 'FACILITY_ENABLED', matchMode: 'equals'}
      }
    };
    if (this.ownerPartyId) {
      event.filters.ownerPartyId = {value: this.ownerPartyId, matchMode: 'equals'};
    }
    if (this.getAll) {
      this.facilitiesService.getAll(JSON.stringify(event)).subscribe((response) => {
        if (response && typeof response.totalRecords === 'number') {
          this.items = response.listData;
          if (this.items.length > 0) {
            if (UserService.getFacilityId()) {
              this.item = this.items.filter(item => item.facilityId === UserService.getFacilityId())[0];
            }
            if (!this.item || !this.item.facilityId) {
              this.item = this.items[0];
            }
            this.onChange(this.item);
          }
        }
      });
    } else {
      this.facilitiesService.getList(JSON.stringify(event)).subscribe((response) => {
        if (response && typeof response.totalRecords === 'number') {
          this.items = response.listData;
          if (this.items.length > 0) {
            if (UserService.getFacilityId()) {
              this.item = this.items.filter(item => item.facilityId === UserService.getFacilityId())[0];
            }
            if (!this.item || !this.item.facilityId) {
              this.item = this.items[0];
            }
            this.onChange(this.item);
          }
        }
      });
    }
  }

  change() {
    UserService.setFacilityId(this.item.facilityId);
    this.onChange(this.item);
  }

}
