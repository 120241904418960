import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {ItemsService} from './items.service';
import {AlertService} from '../../../shared/service/alert.service';

@Component({
  selector: 'app-facility-crup',
  templateUrl: './item-crup.component.html'
})
export class ItemCrupComponent implements OnInit {

  form: FormGroup;

  submitted: boolean;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private itemsService: ItemsService,
    private alertService: AlertService
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      facilityId: new FormControl(''),
      pseudoId: new FormControl('', Validators.required),
      facilityName: new FormControl('', Validators.required)
    });

    this.route.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.itemsService.get(params.get('id')).subscribe((response) => {
          this.form.patchValue(response);
        });
      }
    });
  }

  onSubmit() {
    if (!this.submitted) {
      this.submitted = true;
      if (this.form.controls.facilityId.value) {
        this.itemsService.update(this.form.controls.facilityId.value, this.form.value).subscribe((response) => {
          this.submitted = false;
          if (response.facilityId) {
            this.onSuccess();
          } else {
            this.onError();
          }
        });
      } else {
        this.itemsService.create(this.form.value).subscribe((response) => {
          this.submitted = false;
          if (response.facilityId) {
            this.onSuccess();
          } else {
            this.onError();
          }
        });
      }
    }
  }

  private onSuccess() {
    this.alertService.onSuccess().then(() => {
      this.router.navigateByUrl('/Admin/facilities').then();
    });
  }

  private onError() {
    this.alertService.onError().then();
  }

}
