import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {ItemsService} from './items.service';
import {AlertService} from '../../../shared/service/alert.service';

@Component({
  selector: 'app-product-store',
  templateUrl: './item.component.html'
})
export class ItemComponent implements OnInit {

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private itemsService: ItemsService,
    private alertService: AlertService
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      productStoreId: new FormControl({value: '', disabled: true}),
      storeName: new FormControl({value: '', disabled: true}),
      username: new FormControl({value: '', disabled: true}),
      password: new FormControl({value: '', disabled: true})
    });

    this.route.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.itemsService.get(params.get('id')).subscribe((response) => {
          this.form.patchValue(response);
        });
        this.itemsService.getSystemMessageRemote(params.get('id')).subscribe((response) => {
          this.form.patchValue(response);
        });
      }
    });
  }

  delete() {
    this.itemsService.delete(this.form.controls.productStoreId.value).subscribe(() => {
      this.alertService.onSuccess().then(() => {
        this.router.navigateByUrl('/Admin/facilities').then();
      });
    });
  }

}
