import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import languages from './languages';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  locale: string;

  constructor(private http: HttpClient) {
    this.locale = localStorage.getItem('son_locale');
    if (!this.locale) {
      this.setLocale('vi');
    }
  }

  setLocale(locale: string) {
    if (locale) {
      this.http.post(`mag-base/setLocale`, {language: locale}).subscribe(() => {
        this.locale = locale;
        localStorage.setItem('son_locale', locale);
        location.reload();
      });
    }
  }

  get(value) {
    if (value && value.includes('+')) {
      let result = '';
      value.split('+').forEach((text) => {
        result += ` ${this.get(text)}`;
      });
      return result.trim();
    } else {
      if (languages[value] && languages[value][this.locale] !== undefined) {
        return languages[value][this.locale];
      }
    }
    return value;
  }
}
