import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  key = 'son_authorization_v1';

  constructor(private http: HttpClient) {
  }

  setAuthorization(): void {
    localStorage.setItem(this.key, '');
    this.http.get(`mag-erp/user-account/grant-privilege`).subscribe((response: any) => {
      if (response && response.listData) {
        localStorage.setItem(this.key, JSON.stringify(response.listData));
      }
    });
  }

  getAuthorization(): any {
    let authorization = null;
    try {
      authorization = JSON.parse(localStorage.getItem(this.key));
    } catch (e) {
    }
    return authorization;
  }

  hasAuthorization(screenId: string, artifactId: string, permission: string): boolean {
    if (UserService.getUsername() === 'john.doe') {
      return true;
    } else {
      let has = false;

      const permissions = this.getAuthorization();
      if (permissions && permissions.length > 0 && screenId && artifactId && permission) {
        for (const p of permissions) {
          if (p && p.screenId === screenId && p.artifacts && p.artifacts.length > 0) {
            for (const artifact of p.artifacts) {
              if (artifact && artifact.artifactId === artifactId && artifact.permissions && artifact.permissions.length > 0) {
                if (artifact.permissions.includes('ALL')) {
                  has = true;
                } else {
                  has = artifact.permissions.includes(permission);
                }
                break;
              }
            }
            break;
          }
        }
      }

      return has;
    }
  }

}
