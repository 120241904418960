import {Component, Input, OnInit} from '@angular/core';

import {UserService} from '../../service/user.service';

import {ItemsService as ProductStoresService} from '../../../entities/admin/product-stores';

@Component({
  selector: 'app-select-product-store',
  templateUrl: './select-product-store.component.html'
})
export class SelectProductStoreComponent implements OnInit {

  @Input() organizationPartyId: string;
  @Input() onChange: any;
  item: any;
  items: Array<any>;

  constructor(private productStoresService: ProductStoresService) {
  }

  ngOnInit() {
    let lazyLoadEvent = '';
    if (this.organizationPartyId) {
      lazyLoadEvent = JSON.stringify({filters: {organizationPartyId: {value: this.organizationPartyId, matchMode: 'equals'}}});
    }
    this.productStoresService.getList(lazyLoadEvent).subscribe((response) => {
      if (response && typeof response.totalRecords === 'number') {
        this.items = response.listData;
        if (this.items.length > 0) {
          if (UserService.getProductStoreId()) {
            this.item = this.items.filter(item => item.productStoreId === UserService.getProductStoreId())[0];
          }
          if (!this.item || !this.item.productStoreId) {
            this.item = this.items[0];
          }
          this.onChange(this.item);
        }
      }
    });
  }

  change() {
    UserService.setProductStoreId(this.item.productStoreId);
    this.onChange(this.item);
  }

}
