import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {TableModule} from 'primeng/table';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {KeyFilterModule} from 'primeng/keyfilter';

import {TranslatePipe} from './translate/translate.pipe';

import {BarcodeComponent} from './components/barcode/barcode.component';
import {SearchCustomerComponent} from './components/search-customer/search-customer.component';
import {SearchProductComponent} from './components/search-product/search-product.component';
import {SelectFacilityComponent} from './components/select-facility/select-facility.component';
import {SelectProductStoreComponent} from './components/select-product-store/select-product-store.component';
import {TableFilterComponent} from './components/table-filter/table-filter.component';
import {UploadProductComponent} from './components/upload-product/upload-product.component';

@NgModule({
  declarations: [
    TranslatePipe,
    BarcodeComponent,
    SearchCustomerComponent,
    SearchProductComponent,
    SelectFacilityComponent,
    SelectProductStoreComponent,
    TableFilterComponent,
    UploadProductComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    ScrollPanelModule,
    AutoCompleteModule,
    SelectButtonModule,
    ButtonModule,
    DialogModule,
    KeyFilterModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    ScrollPanelModule,
    AutoCompleteModule,
    SelectButtonModule,
    ButtonModule,
    DialogModule,
    KeyFilterModule,
    TranslatePipe,
    BarcodeComponent,
    SearchCustomerComponent,
    SearchProductComponent,
    SelectFacilityComponent,
    SelectProductStoreComponent,
    TableFilterComponent,
    UploadProductComponent
  ]
})
export class SharedModule {
}
