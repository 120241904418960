import {Component, OnDestroy, OnInit} from '@angular/core';

import {LazyLoadEvent} from 'primeng/api';

import {Col} from '../../../shared/components/col.model';

import {BreadcrumbService} from '../../../layout/breadcrumb/breadcrumb.service';

import {ItemsService} from './items.service';

@Component({
  selector: 'app-facilities',
  templateUrl: './items.component.html'
})
export class ItemsComponent implements OnInit, OnDestroy {

  cols: Array<Col>;
  items: Array<any>;
  totalRecords: number;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private itemsService: ItemsService
  ) {
  }

  ngOnInit() {
    this.breadcrumbService.setRouterLinkCreate('/Admin/facilities/create');

    this.cols = [
      new Col('pseudoId', 'pseudoId', 300, 'contains', 'word-break-all'),
      new Col('facilityName', 'facilityName', 'auto', 'contains')
    ];
  }

  ngOnDestroy(): void {
    this.breadcrumbService.setRouterLinkCreate(null);
  }

  loadLazy(event: LazyLoadEvent) {
    this.itemsService.getList(JSON.stringify(event)).subscribe((response) => {
      if (response && typeof response.totalRecords === 'number') {
        this.totalRecords = response.totalRecords;
        this.items = response.listData;
      }
    });
  }

}
