import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  private readonly url = 'mag-erp-admin/productStores';

  constructor(private http: HttpClient) {
  }

  getList(lazyLoadEvent: string): Observable<any> {
    return this.http.get(`${this.url}?lazyLoadEvent=${lazyLoadEvent}`);
  }

  getAll(): Observable<any> {
    return this.http.get(`${this.url}/all`);
  }

  get(id: string): Observable<any> {
    return this.http.get(`${this.url}/${id}`);
  }

  create(item: any): Observable<any> {
    return this.http.post(`${this.url}`, item);
  }

  update(id: string, item: any): Observable<any> {
    return this.http.put(`${this.url}/${id}`, item);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${this.url}/${id}`);
  }

  getSystemMessageRemote(id: string): Observable<any> {
    return this.http.get(`${this.url}/${id}/systemMessageRemote`);
  }

  getShippingOptions(id: string, params: any): Observable<any> {
    return this.http.get(`${this.url}/${id}/shippingOptions`, {params});
  }

  getFacilities(id: string): Observable<any> {
    return this.http.get(`${this.url}/${id}/facilities`);
  }

}
