import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceJobService {

  private readonly url = 'mag-erp-admin/service-jobs';

  constructor(private http: HttpClient) {
  }

  get(jobName: string): Observable<any> {
    return this.http.get(`${this.url}/${jobName}`);
  }

}
