import {Component, Input, OnInit} from '@angular/core';

import {Table} from 'primeng/table';
import {Col} from '../col.model';

@Component({
  selector: 'tr[app-table-filter]',
  templateUrl: './table-filter.component.html'
})
export class TableFilterComponent implements OnInit {

  @Input() table: Table;
  @Input() columns: Array<Col>;
  @Input() tableCheckbox: boolean;
  @Input() expandColumn: boolean;
  @Input() seqColumn: boolean;
  @Input() actionColumn: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
