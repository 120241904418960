import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {BreadcrumbService} from '../../../../layout/breadcrumb/breadcrumb.service';
import {ItemsService} from '../items.service';
import {AuthService} from '../../../../shared/service/auth.service';

@Component({
  selector: 'app-customer',
  templateUrl: './item.component.html'
})
export class ItemComponent implements OnInit, OnDestroy {

  form: FormGroup;

  postalAddressList: Array<any>;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private itemsService: ItemsService,
    public authService: AuthService
  ) {
  }

  ngOnInit() {
    this.breadcrumbService.setRouterLinkBack('/Sales/customers');

    this.form = this.fb.group({
      partyId: new FormControl(''),
      partyName: new FormControl({value: '', disabled: true}, Validators.required),
      gender: new FormControl({value: '', disabled: true}),
      birthDate: new FormControl({value: '', disabled: true}),
      contactNumber: new FormControl({value: '', disabled: true}, Validators.required),
      emailAddress: new FormControl({value: '', disabled: true}),
      address1: new FormControl({value: '', disabled: true}, Validators.required),
      countyGeoId: new FormControl({value: '', disabled: true}),
      stateProvinceGeoId: new FormControl({value: '', disabled: true}),
      countryGeoId: new FormControl('VNM')
    });

    this.postalAddressList = new Array<any>();
    this.route.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.itemsService.get(params.get('id')).subscribe((response) => {
          if (response.birthDate) {
            response.birthDate = new Date(response.birthDate);
          }
          this.form.patchValue(response);
        });
        this.itemsService.getContactInfos(params.get('id')).subscribe(({postalAddressList}) => {
          this.postalAddressList = postalAddressList;
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.breadcrumbService.setRouterLinkBack(null);
  }

}
