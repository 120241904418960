import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

import {LazyLoadEvent} from 'primeng/api';

import {Col} from '../../../../shared/components/col.model';

import {ItemsService} from '../items.service';
import {ItemsService as OrdersService} from '../../orders';

@Component({
  selector: 'app-customer-orders',
  templateUrl: './item-orders.component.html',
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class ItemOrdersComponent implements OnInit {

  @Input() partyId: string;

  orderIds: Array<string>;

  cols: Array<Col>;
  items: Array<any>;
  totalRecords: number;

  constructor(
    private itemsService: ItemsService,
    private ordersService: OrdersService
  ) {
    this.onSelectProduct = this.onSelectProduct.bind(this);
  }

  ngOnInit(): void {
    this.cols = [
      new Col('orderId', 'pseudoId', 120, 'contains'),
      new Col('placedDate', 'placedDate', 120, 'contains', null, 'date'),
      new Col('partTotal', 'grandTotal', 120, 'numeric', 'text-right', 'number'),
      new Col('storeName', 'ProductStore', 180, 'contains'),
      new Col('facility', 'facility', 150, 'contains'),
      new Col('status', 'status', 130, 'contains'),
      new Col('partName', 'description', 'auto', 'contains', null, 'editable')
    ];
  }

  reLoadLazy(): void {
    this.totalRecords = 0;
    this.items = [];
    this.loadLazy({first: 0, rows: 10, filters: {}});
  }

  loadLazy(event: LazyLoadEvent): void {
    if (this.partyId) {
      event.filters.customerPartyId = {value: this.partyId, matchMode: 'equals'};
      if (this.orderIds) {
        event.filters.orderId = {value: this.orderIds, matchMode: 'in'};
      }
      this.ordersService.getList(JSON.stringify(event)).subscribe((response) => {
        if (response && typeof response.totalRecords === 'number') {
          this.totalRecords = response.totalRecords;
          this.items = response.listData;
        }
      });
    }
  }

  onSelectProduct({productId}): void {
    if (this.partyId && productId) {
      this.itemsService.findOrdersByProduct(this.partyId, productId).subscribe(({orderIds}) => {
        this.orderIds = orderIds;
        this.reLoadLazy();
      });
    }
  }

}
