import {Component, Input, OnInit} from '@angular/core';

import {ProductService} from '../../../entities/service/product.service';

@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html'
})
export class SearchProductComponent implements OnInit {

  @Input() disabled: boolean;
  @Input() productStoreId: string;
  @Input() getInventory: string;
  @Input() isExcludeCombo: string;
  @Input() isIncludeDisabled: string;
  @Input() onSelect: any;
  item: any;
  items: Array<any>;

  submitted: boolean;

  constructor(private productService: ProductService) {
  }

  ngOnInit() {
  }

  search(event) {
    this.productService.search({
      productStoreId: this.productStoreId,
      isExcludeCombo: this.isExcludeCombo,
      isIncludeDisabled: this.isIncludeDisabled,
      term: event.query,
    }).subscribe((response) => {
      this.items = response.listData;
      if (this.items && this.items.length > 0) {
        this.items.forEach((item) => {
          item.productName = `${item.productName} - ${item.pseudoId}`;
        });
        if (this.getInventory && this.getInventory.trim() && this.items.length < 30) {
          this.items.forEach((item) => {
            if (item.productId) {
              this.productService.getAssets(item.productId).subscribe(({listData: assets}) => {
                if (assets && assets.length > 0) {
                  let availableToPromiseTotal = 0;
                  assets.forEach((asset) => {
                    if (asset && asset.facilityId === this.getInventory) {
                      availableToPromiseTotal = asset.availableToPromiseTotal;
                    }
                  });
                  item.productName = `${item.productName} - ( ${availableToPromiseTotal} )`;
                }
              });
            }
          });
        }
      }
    });
  }

  select() {
    this.onSelect(this.item);
    this.submitted = false;
    setTimeout(() => {
      this.submitted = true;
    }, 100);
  }

  clean() {
    if (this.submitted) {
      this.item = null;
    }
  }
}
