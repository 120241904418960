import {Component, OnDestroy, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

import {LazyLoadEvent} from 'primeng/api';

import {Col} from '../../../shared/components/col.model';

import {BreadcrumbService} from '../../../layout/breadcrumb/breadcrumb.service';

import {ItemsService} from './items.service';
import {OrderService} from '../../service/order.service';
import {ItemsService as ProductStoresService} from '../../../entities/admin/product-stores';
import {AlertService} from '../../../shared/service/alert.service';
import {AuthService} from '../../../shared/service/auth.service';

@Component({
  selector: 'app-orders',
  templateUrl: './items.component.html',
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class ItemsComponent implements OnInit, OnDestroy {

  productStoreId: string;

  facilities: Array<any>;
  facility: any;

  cols: Array<Col>;
  items: Array<any>;
  totalRecords: number;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private itemsService: ItemsService,
    private orderService: OrderService,
    private storesService: ProductStoresService,
    private alertService: AlertService,
    public authService: AuthService
  ) {
    this.onChangeStore = this.onChangeStore.bind(this);
  }

  ngOnInit() {
    if (this.authService.hasAuthorization('SalesListOrder', 'SalesListOrder1', 'CREATE')) {
      this.breadcrumbService.setRouterLinkCreate('/Sales/orders/create');
    }

    this.cols = [
      new Col('orderId', 'pseudoId', 120, 'contains'),
      new Col('placedDate', 'placedDate', 140, 'contains', null, 'date'),
      new Col('partTotal', 'grandTotal', 150, 'numeric', 'text-right', 'number'),
      new Col('customerParty', 'Customer', 250, 'contains'),
      new Col('status', 'status', 150, 'contains'),
      new Col('partName', 'description', 'auto', 'contains', null, 'editable')
    ];
  }

  ngOnDestroy(): void {
    this.breadcrumbService.setRouterLinkCreate(null);
  }

  onChangeStore({productStoreId}) {
    if (this.productStoreId !== productStoreId) {
      this.productStoreId = productStoreId;
      this.loadFacilities();
    }
  }

  loadFacilities() {
    if (this.productStoreId) {
      this.storesService.getFacilities(this.productStoreId).subscribe(({listData}) => {
        this.facilities = listData;
        if (this.facilities.length > 0) {
          this.facility = this.facilities[0];
          this.reLoadLazy();
        }
      });
    }
  }

  reLoadLazy() {
    this.totalRecords = 0;
    this.items = [];
    this.loadLazy({first: 0, rows: 10, filters: {}});
  }

  loadLazy(event: LazyLoadEvent) {
    if (this.facility && this.facility.facilityId) {
      event.filters.productStoreId = {value: this.productStoreId, matchMode: 'equals'};
      event.filters.facilityId = {value: this.facility.facilityId, matchMode: 'equals'};
      this.itemsService.getList(JSON.stringify(event)).subscribe((response) => {
        if (response && typeof response.totalRecords === 'number') {
          this.totalRecords = response.totalRecords;
          this.items = response.listData;
        }
      });
    }
  }

  savePartName(rowData: any) {
    this.orderService.patchPart(rowData.orderId, rowData.orderPartSeqId, {
      partName: rowData.partName
    }).subscribe(() => {
      this.onSuccess();
      document.getElementById('main').click();
    });
  }

  private onSuccess() {
    this.alertService.onSuccess().then();
  }

}
