export class Col {
  constructor(
    public field?: string,
    public header?: string,
    public width?: any,
    public filterMatchMode?: string,
    public className?: string,
    public type?: string
  ) {
  }
}
