import {Component, OnInit} from '@angular/core';

import {LazyLoadEvent} from 'primeng/api';

import {Col} from '../../../shared/components/col.model';

import {ItemsService} from './items.service';
import {AuthService} from '../../../shared/service/auth.service';

@Component({
  selector: 'app-customers',
  templateUrl: './items.component.html'
})
export class ItemsComponent implements OnInit {

  cols: Array<Col>;
  items: Array<any>;
  totalRecords: number;

  constructor(
    private itemsService: ItemsService,
    public authService: AuthService
  ) {
  }

  ngOnInit() {
    this.cols = [
      new Col('pseudoId', 'pseudoId', 160, 'contains', 'word-break-all'),
      new Col('partyName', 'Customer', 200, 'contains'),
      new Col('contactNumber', 'Phone', 160, 'contains'),
      new Col('address', 'Address', 'auto', 'contains')
    ];
  }

  loadLazy(event: LazyLoadEvent) {
    this.itemsService.getList(JSON.stringify(event)).subscribe((response) => {
      if (response && typeof response.totalRecords === 'number') {
        this.totalRecords = response.totalRecords;
        this.items = response.listData;
      }
    });
  }

}
