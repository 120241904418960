import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {ModuleMenuModel as ModuleMenu} from './module-menu.model';

import {TranslateService} from '../translate/translate.service';

import {HeaderService} from '../../layout/header/header.service';
import {LeftSidebarService} from '../../layout/left-sidebar/left-sidebar.service';
import {BreadcrumbService} from '../../layout/breadcrumb/breadcrumb.service';
import {AuthService} from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ModuleMenuService {

  private module: ModuleMenu;
  private readonly modules: ModuleMenu[];

  constructor(
    private translateService: TranslateService,
    private headerService: HeaderService,
    private leftSidebarService: LeftSidebarService,
    private breadcrumbService: BreadcrumbService,
    public authService: AuthService
  ) {
    this.modules = [
      new ModuleMenu('Home', '/Home/dashboard', 'home', 'Home', [
        new ModuleMenu('', '/Home/dashboard', 'home', 'Dashboard'),
        new ModuleMenu('', '/Sales/dashboard', 'shopping_cart', 'Sales'),
        new ModuleMenu('', '/Purchases/dashboard', 'flight_takeoff', 'Purchases'),
        new ModuleMenu('', '/Logistics/dashboard', 'dns', 'Logistics'),
        new ModuleMenu('', '/Accounting/dashboard', 'attach_money', 'Finance'),
        new ModuleMenu('', '/Admin/dashboard', 'build', 'Admin')
      ]),
      new ModuleMenu('Sales', '/Sales/dashboard', 'shopping_cart', 'Sales', [
        new ModuleMenu('', '/Sales/dashboard', 'home', 'Dashboard'),
        new ModuleMenu('', '/Sales/orders-hot/create', 'add_shopping_cart', 'CreateOrder', null, this.authService.hasAuthorization('SalesCreateOrder', 'SalesCreateOrder1', 'CREATE')),
        new ModuleMenu('', '/Sales/orders', 'shopping_cart', 'Order'),
        new ModuleMenu('', '', 'grain', 'Tiki', [
          new ModuleMenu('', '/Sales/tiki/confirm', '', 'Confirm'),
          new ModuleMenu('', '/Sales/tiki/categories', '', 'Categories'),
          new ModuleMenu('', '/Sales/tiki/product-error', '', 'ProductError'),
          new ModuleMenu('', '/Sales/tiki/inventory-NA', '', 'inventoryNotAvailable'),
          new ModuleMenu('', '/Sales/tiki/histories', '', 'History'),
          new ModuleMenu('', '/Sales/tiki/reports', '', 'Report'),
          new ModuleMenu('', '/Sales/tiki/shipping-fee-customer', '', 'ShippingFeeCustomer')
        ]),
        new ModuleMenu('', '', 'grain', 'Sendo', [
          new ModuleMenu('', '/Sales/sendo/orders', '', 'Order'),
          new ModuleMenu('', '/Sales/sendo/product-error', '', 'ProductError')
        ]),
        new ModuleMenu('', '', 'grain', 'Shopee', [
          new ModuleMenu('', '/Sales/shopee/confirm', '', 'Confirm'),
          new ModuleMenu('', '/Sales/shopee/orders', '', 'Order'),
          new ModuleMenu('', '/Sales/shopee/product-error', '', 'ProductError')
        ]),
        new ModuleMenu('', '', 'grain', 'Lazada', [
          new ModuleMenu('', '/Sales/lazada/confirm', '', 'Confirm'),
          new ModuleMenu('', '/Sales/lazada/products', '', 'Product'),
          new ModuleMenu('', '/Sales/lazada/orders', '', 'Order'),
          new ModuleMenu('', '/Sales/lazada/product-error', '', 'ProductError'),
          new ModuleMenu('', '/Sales/lazada/shipping-fee', '', 'ShippingFee')
        ]),
        new ModuleMenu('', '/Sales/customers', 'supervisor_account', 'Customer'),
        new ModuleMenu('', '/Sales/products', 'blur_on', 'Product'),
        new ModuleMenu('', '/Sales/product-dimensions', '3d_rotation', 'ProductDimension'),
        new ModuleMenu('', '/Sales/cogs', 'settings_input_component', 'Cogs'),
        new ModuleMenu('', '', 'insert_chart', 'Report', [
          new ModuleMenu('', '/Sales/reports/revenue', '', 'Revenue'),
          new ModuleMenu('', '/Sales/reports/sales', '', 'Sales'),
          new ModuleMenu('', '/Sales/reports/not-mapping-identify', '', 'SP chưa đăng'),
          new ModuleMenu('', '/Sales/reports/report-1', '', 'Doanh số theo kênh'),
          new ModuleMenu('', '/Sales/reports/report-2', '', 'Doanh số theo SP'),
          new ModuleMenu('', '/Sales/reports/report-3', '', 'Lịch sử giá vốn'),
          new ModuleMenu('', '/Sales/reports/report-4', '', 'Giá vốn 2 miền'),
          new ModuleMenu('', '/Sales/reports/order-product-profit', '', 'Thống kê lợi nhuận'),
          new ModuleMenu('', '/Sales/reports/sale-product-period', '', 'Báo cáo sản lượng', null, this.authService.hasAuthorization('SalesReportProdInPeriod', 'SalesReportProdInPeriod1', 'VIEW')),
        ])
      ]),
      new ModuleMenu('Purchases', '/Purchases/dashboard', 'flight_takeoff', 'Purchases', [
        new ModuleMenu('', '/Purchases/dashboard', 'home', 'Dashboard'),
        new ModuleMenu('', '/Purchases/orders-hot/create', 'add_shopping_cart', 'CreateOrder', null, this.authService.hasAuthorization('PurchaseCreateOrder', 'PurchaseCreateOrder1', 'CREATE')),
        new ModuleMenu('', '/Purchases/orders', 'shopping_cart', 'Order'),
        // new ModuleMenu('', '/Purchases/lack-of-product', 'announcement', 'inventoryNotAvailable'),
        new ModuleMenu('', '/Purchases/products', 'blur_on', 'Product'),
        new ModuleMenu('', '/Purchases/suppliers', 'person_pin', 'Supplier')
      ]),
      new ModuleMenu('Logistics', '/Logistics/dashboard', 'dns', 'Logistics', [
        new ModuleMenu('', '/Logistics/dashboard', 'home', 'Dashboard'),
        new ModuleMenu('', '/Logistics/products', 'blur_on', 'Product'),
        new ModuleMenu('', '', 'grain', 'Tiki', [
          new ModuleMenu('', '/Logistics/tiki/orders', '', 'Order'),
          new ModuleMenu('', '/Logistics/tiki/outgoing', '', 'OutgoingShipment'),
          new ModuleMenu('', '/Logistics/tiki/not-packed', '', 'NotPacked'),
          new ModuleMenu('', '/Logistics/tiki/wait-shipping', '', 'NotIssued'),
          new ModuleMenu('', '/Logistics/tiki/requisitions', '', 'Requisitions'),
          new ModuleMenu('', '/Logistics/tiki/refused-requisitions', '', 'RefusedRequisitions'),
          new ModuleMenu('', '/Logistics/tiki/withdrawals', '', 'Withdrawals'),
          new ModuleMenu('', '/Logistics/tiki/transfers', '', 'TransferShipment'),
          new ModuleMenu('', '/Logistics/tiki/transfer-plan', '', 'Plan+TransferShipment'),
          new ModuleMenu('', '/Logistics/tiki/lost-goods', '', 'LostGoods'),
          new ModuleMenu('', '/Logistics/tiki/inventories', '', 'InventoryAssets'),
          new ModuleMenu('', '/Logistics/tiki/minimum-stock', '', 'MinimumStock'),
          new ModuleMenu('', '/Logistics/tiki/returns', '', 'Returns')
        ]),
        new ModuleMenu('', '', 'grain', 'Sendo', [
          new ModuleMenu('', '/Logistics/sendo/orders', '', 'Order'),
          new ModuleMenu('', '/Logistics/sendo/outgoing', '', 'OutgoingShipment')
        ]),
        new ModuleMenu('', '', 'grain', 'Shopee', [
          new ModuleMenu('', '/Logistics/shopee/print', '', 'PrintOrder'),
          new ModuleMenu('', '/Logistics/shopee/outgoing', '', 'OutgoingShipment'),
          new ModuleMenu('', '/Logistics/shopee/wait-shipping', '', 'NotIssued'),
          new ModuleMenu('', '/Logistics/shopee/returns', '', 'Returns')
        ]),
        new ModuleMenu('', '', 'grain', 'Lazada', [
          new ModuleMenu('', '/Logistics/lazada/print', '', 'PrintOrder'),
          new ModuleMenu('', '/Logistics/lazada/outgoing', '', 'OutgoingShipment'),
          new ModuleMenu('', '/Logistics/lazada/wait-shipping', '', 'NotIssued'),
          new ModuleMenu('', '/Logistics/lazada/returns', '', 'Returns')
        ]),
        new ModuleMenu('', '/Logistics/picklists', 'clear_all', 'Picklist'),
        new ModuleMenu('', '/Logistics/outgoing-shipments', 'call_made', 'OutgoingShipment'),
        new ModuleMenu('', '/Logistics/incoming-shipments', 'call_received', 'IncomingShipment'),
        new ModuleMenu('', '/Logistics/transfer-shipments', 'compare_arrows', 'TransferShipment'),
        new ModuleMenu('', '/Logistics/returns', 'archive', 'Returns'),
        new ModuleMenu('', '/Logistics/physical-changes', 'shuffle', 'PhysicalChanges'),
        new ModuleMenu('', '/Logistics/inventory-assets', 'list', 'InventoryAssets'),
        new ModuleMenu('', '/Logistics/minimum-stock', 'access_alarm', 'MinimumStock'),
        new ModuleMenu('', '/Logistics/stock-take', 'assignment', 'StockTake'),
        new ModuleMenu('', '/Logistics/inventory-histories', 'access_time', 'History')
      ]),
      new ModuleMenu('Accounting', '/Accounting/dashboard', 'attach_money', 'Finance', [
        new ModuleMenu('', '/Accounting/dashboard', 'home', 'Dashboard'),
        new ModuleMenu('', '/Accounting/supplier-liabilities', 'attach_money', 'SupplierLiabilities'),
        new ModuleMenu('', '/Accounting/sales-aggregation', 'settings_input_component', 'Tính lương Nhân Viên'),
        new ModuleMenu('', '', 'grain', 'Tiki', [
          new ModuleMenu('', '/Accounting/tiki/order-paid', '', 'Paid'),
          new ModuleMenu('', '/Accounting/tiki/order-unpaid', '', 'Unpaid')
        ]),
        new ModuleMenu('', '', 'grain', 'Lazada', [
          new ModuleMenu('', '/Accounting/lazada/order-paid', '', 'Paid'),
          new ModuleMenu('', '/Accounting/lazada/order-unpaid', '', 'Unpaid')
        ]),
        new ModuleMenu('', '', 'grain', 'Shopee', [
          new ModuleMenu('', '/Accounting/shopee/order-paid', '', 'Paid'),
          new ModuleMenu('', '/Accounting/shopee/order-unpaid', '', 'Unpaid'),
          new ModuleMenu('', '/Accounting/shopee/wallet', '', 'Wallet')
        ]),
        new ModuleMenu('', '', 'insert_chart', 'Report', [
          new ModuleMenu('', '/Accounting/reports/report-1', '', 'SP chưa có giá vốn')
        ]),
        new ModuleMenu('', '/Accounting/bank-account', 'attach_money', 'TK Ngân hàng'),
      ]),
      new ModuleMenu('Admin', '/Admin/dashboard', 'build', 'Admin', [
        new ModuleMenu('', '/Admin/dashboard', 'home', 'Dashboard'),
        new ModuleMenu('', '/Admin/facilities', 'domain', 'facility'),
        new ModuleMenu('', '/Admin/product-stores', 'store', 'ProductStore'),
        new ModuleMenu('', '/Admin/stores', 'store', 'Sàn liên kết'),
        new ModuleMenu('', '/Admin/geos', 'terrain', 'Geo'),
        new ModuleMenu('', '/Admin/enums', 'graphic_eq', 'Enumeration'),
        new ModuleMenu('', '/Admin/statuses', 'graphic_eq', 'Status'),
        new ModuleMenu('', '/Admin/product-features', 'graphic_eq', 'Product Feature'),
        new ModuleMenu('', '/Admin/categories', 'graphic_eq', 'Category'),
        new ModuleMenu('', '/Admin/privileges', 'graphic_eq', 'Privileges')
      ])
    ];

    this.headerService.setModules(this.modules);
    this.leftSidebarService.setModule(this.modules[0]);
  }

  setModule(next: ActivatedRouteSnapshot) {
    let path = next.routeConfig.path;
    if (!path) {
      path = 'Home';
    }
    this.modules.forEach((module) => {
      if (module.id === path) {
        this.module = module;
        this.leftSidebarService.setModule(this.module);
        this.breadcrumbService.setModule(this.module);
      }
    });
  }

  setMenu(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let url = state.url;

    let isAction = true;
    this.breadcrumbService.setAction(null);
    if (this.module && this.module.children) {
      this.module.children.forEach((module) => {
        if (module.routerLink === url) {
          this.breadcrumbService.setMenu(module);
          isAction = false;
        } else {
          if (module && module.children) {
            module.children.forEach((child) => {
              if (child.routerLink === url) {
                this.breadcrumbService.setMenu(module);
                this.breadcrumbService.setAction(child.title);
                isAction = false;
              }
            });
          }
        }
      });
    }
    if (isAction) {
      const path = next.routeConfig.path;
      if (path === 'detail/:id') {
        this.breadcrumbService.setAction(this.translateService.get('Detail') + ' #' + next.params.id);
        url = url.split('/detail/')[0];
      } else if (path === 'create') {
        this.breadcrumbService.setAction('Create');
        url = url.split('/create')[0];
      } else if (path === 'update/:id') {
        this.breadcrumbService.setAction(this.translateService.get('Update') + ' #' + next.params.id);
        url = url.split('/update/')[0];
      }
      if (this.module && this.module.children) {
        this.module.children.forEach((module) => {
          if (module.routerLink === url) {
            this.breadcrumbService.setMenu(module);
          } else {
            if (module && module.children) {
              module.children.forEach((child) => {
                if (child.routerLink === url) {
                  this.breadcrumbService.setMenu(child);
                }
              });
            }
          }
        });
      }
    }
  }
}
