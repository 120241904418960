import {Component, Input, OnInit} from '@angular/core';

import {ProductService} from '../../../entities/service/product.service';
import {AlertService} from '../../service/alert.service';
import {XlsxService} from '../../../entities/service/xlsx.service';

@Component({
  selector: 'app-upload-product',
  templateUrl: './upload-product.component.html'
})
export class UploadProductComponent implements OnInit {

  @Input() visible: number;

  items: Array<any>;

  constructor(
    private productService: ProductService,
    private alertService: AlertService
  ) {
  }

  ngOnInit() {
  }

  download() {
    const aoa = [
      ['isCombo', 'pseudoId', 'productName', 'barcode', 'description'],
      ['Là SP Combo', 'Mã SP', 'Tên SP', 'Mã vạch', 'Mô tả']
    ];
    XlsxService.saveAsExcelFile(aoa, `san-pham`);
  }

  onUpload(event) {
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      XlsxService.readExcelFile(event.target.files[0]).then((data: Array<any>) => {
        if (data && data.length > 2 && data[0].isCombo === 'Là SP Combo') {
          data.shift();
          const invalid = [];
          data.forEach((item) => {
            if (!item.pseudoId || !item.productName) {
              invalid.push(item.pseudoId);
            } else {
              if (!item.isCombo) {
                item.isCombo = 'N';
              }
            }
          });
          if (invalid.length) {
            this.alertService.onError(`Lỗi SP ${invalid.join(', ')}`).then();
          } else {
            this.items = data;
          }
        } else {
          this.alertService.onError('File không hợp lệ').then();
        }
      });
    }
  }

  delete(rowData: any) {
    if (rowData) {
      this.items.splice(this.items.indexOf(rowData), 1);
      this.alertService.onSuccess().then();
    }
  }

  onSubmit() {
    if (this.items && this.items.length > 0) {
      const complete = [];
      this.items.forEach((item) => {
        complete.push(this.create(item));
      });
      Promise.all(complete).then((results) => {
        let success = 0;
        const error = [];
        if (results && results.length) {
          results.forEach((result) => {
            if (result) {
              error.push(result);
            } else {
              success++;
            }
          });
        }
        this.alertService.onMessage(`${success}/${this.items.length} Thành công`, `${error.length}/${this.items.length} Lỗi: ${error.join(', ')}`).then(() => {
          this.visible = 0;
        });
      });
    }
  }

  create(item: any) {
    return new Promise((resolve) => {
      this.productService.create(item).subscribe(() => {
        resolve();
      }, (error) => {
        let errors = '';
        if (error && error.error && error.error.errors) {
          errors = error.error.errors;
        }
        resolve(`${item.pseudoId}: ${errors}`);
      });
    });
  }

}
