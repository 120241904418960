import {Component, Input, OnInit} from '@angular/core';

import {Col} from '../../../../shared/components/col.model';

import {OrderService} from '../../../service/order.service';

@Component({
  selector: 'app-customer-order-expansion',
  templateUrl: './item-order-expansion.component.html'
})
export class ItemOrderExpansionComponent implements OnInit {

  @Input() orderId: string;
  @Input() orderPartSeqId: string;

  cols: Array<Col>;
  items: Array<any>;

  amountTotal: number;
  quantityTotal: number;

  constructor(private orderService: OrderService) {
  }

  ngOnInit() {
    this.cols = [
      new Col('pseudoId', 'pseudoId', 160, null, 'word-break-all'),
      new Col('productName', 'productName', 'auto'),
      new Col('unitListPrice', 'unitListPrice', 130, null, 'text-right', 'number'),
      new Col('unitAmount', 'unitAmount', 130, null, 'text-right', 'number'),
      new Col('quantity', 'quantity', 120, null, 'text-right', 'number'),
      new Col('itemTotal', 'itemTotal', 120, null, 'text-right', 'number')
    ];

    if (this.orderId && this.orderPartSeqId) {
      this.orderService.getItems(this.orderId, this.orderPartSeqId).subscribe(({listData}) => {
        this.items = listData;
        this.amountTotal = 0;
        this.quantityTotal = 0;
        if (this.items && this.items.length) {
          this.items.forEach((item) => {
            this.amountTotal += (item.itemTotal || 0);
            this.quantityTotal += (item.quantity || 0);
          });
        }
      });
    }
  }

}
