import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  private readonly url = 'mag-erp-admin/customers';

  constructor(private http: HttpClient) {
  }

  getList(lazyLoadEvent: string): Observable<any> {
    return this.http.get(`${this.url}?lazyLoadEvent=${lazyLoadEvent}`);
  }

  get(partyId: string): Observable<any> {
    return this.http.get(`${this.url}/${partyId}`);
  }

  create(item: any): Observable<any> {
    return this.http.post(`${this.url}`, item);
  }

  update(partyId: string, item: any): Observable<any> {
    return this.http.put(`${this.url}/${partyId}`, item);
  }

  search(params: any): Observable<any> {
    return this.http.get(`${this.url}/search`, {params});
  }

  getContactInfos(partyId: string): Observable<any> {
    return this.http.get(`${this.url}/${partyId}/contactInfos`);
  }

  createContactInfos(partyId: string, item: any): Observable<any> {
    return this.http.post(`${this.url}/${partyId}/contactInfos`, item);
  }

  updateContactInfos(partyId: string, postalContactMechId: string, item: any): Observable<any> {
    return this.http.put(`${this.url}/${partyId}/contactInfos/${postalContactMechId}`, item);
  }

  findOrdersByProduct(partyId: string, productId: string): Observable<any> {
    return this.http.post(`${this.url}/${partyId}/orders/find-orders-by-product`, {productId});
  }

}
