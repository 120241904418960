/*-----------------------------------------------------------------------------
 - Created by Hoanm on 10/17/19 8:33 PM.                                      -
 -----------------------------------------------------------------------------*/
import * as xlsx from 'xlsx';
import * as FileSaver from 'file-saver';

export class XlsxService {

  static readExcelFile(file: any, header?: number) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event && event.target) {
          const wb = xlsx.read((event.target as FileReader).result, {type: 'binary'});
          const data = xlsx.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {header});
          resolve(data);
        } else {
          resolve();
        }
      };
      reader.onerror = () => {
        resolve();
      };
      reader.readAsBinaryString(file);
    });
  }

  static saveAsExcelFile(aoa: Array<any>, fileName: string): void {
    if (aoa && aoa.length > 0) {
      const worksheet = xlsx.utils.aoa_to_sheet(aoa);
      const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']};
      const excelBuffer: any = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([excelBuffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
  }

}
