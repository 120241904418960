import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {ModuleMenuModel as ModuleMenu} from '../../shared/module-menu/module-menu.model';

import {HeaderService as Service} from './header.service';
import {TranslateService} from '../../shared/translate/translate.service';
import {LoginService} from '../../pages/login/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  locale: string;

  modules: ModuleMenu[];

  constructor(
    private router: Router,
    private service: Service,
    private translateService: TranslateService,
    private loginService: LoginService
  ) {
  }

  ngOnInit() {
    this.locale = this.translateService.locale;

    this.service.getModules().subscribe((modules) => {
      this.modules = modules;
    });
  }

  setLocale(locale: string) {
    this.locale = locale;
    this.translateService.setLocale(locale);
  }

  logout() {
    this.loginService.logout().subscribe(() => {
      this.router.navigateByUrl('/login').then();
    });
  }

}
