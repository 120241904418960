import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {EntitiesComponent} from './entities/entities.component';

import {Interceptor} from './shared/interceptor';

import {SharedModule} from './shared/shared.module';

import {HeaderComponent} from './layout/header/header.component';
import {FooterComponent} from './layout/footer/footer.component';
import {RightSidebarComponent} from './layout/right-sidebar/right-sidebar.component';
import {LeftSidebarComponent} from './layout/left-sidebar/left-sidebar.component';
import {BreadcrumbComponent} from './layout/breadcrumb/breadcrumb.component';
import {ProgressComponent} from './layout/progress/progress.component';
import {LoginComponent} from './pages/login/login.component';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {ChangePasswordComponent} from './pages/change-password/change-password.component';

@NgModule({
  declarations: [
    AppComponent,
    EntitiesComponent,
    HeaderComponent,
    FooterComponent,
    RightSidebarComponent,
    LeftSidebarComponent,
    BreadcrumbComponent,
    ProgressComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
