import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {AuthService} from '../../shared/service/auth.service';
import {UserService} from '../../shared/service/user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
  }

  login(item: any): Observable<any> {
    return this.http.post('mag-erp/login', item)
      .pipe(tap(response => {
        if (response) {
          this.authService.setAuthorization();
          UserService.setUsername(item.username);
        }
      }));
  }

  changePassword(item: any): Observable<any> {
    return this.http.put('mag-erp/changePassword', item);
  }

  logout(): Observable<any> {
    return this.http.post('mag-erp/logout', null);
  }
}
