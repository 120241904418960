import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {RouteGuard} from './shared/guard/route.guard';

import {EntitiesComponent} from './entities/entities.component';

import {LoginComponent} from './pages/login/login.component';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {ChangePasswordComponent} from './pages/change-password/change-password.component';

const routes: Routes = [
  {
    path: '',
    component: EntitiesComponent,
    children: [
      {path: '', redirectTo: 'Home/dashboard', pathMatch: 'full'},
      {
        path: 'Home',
        loadChildren: () => import('./entities/home/home.module').then(mod => mod.HomeModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'Accounting',
        loadChildren: () => import('./entities/accounting/accounting.module').then(mod => mod.AccountingModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'Admin',
        loadChildren: () => import('./entities/admin/admin.module').then(mod => mod.AdminModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'Logistics',
        loadChildren: () => import('./entities/logistics/logistics.module').then(mod => mod.LogisticsModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'Purchases',
        loadChildren: () => import('./entities/purchases/purchases.module').then(mod => mod.PurchasesModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {
        path: 'Sales',
        loadChildren: () => import('./entities/sales/sales.module').then(mod => mod.SalesModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      }
    ]
  },
  {path: 'login', component: LoginComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'change-password', component: ChangePasswordComponent},
  {path: '**', redirectTo: 'Home/dashboard'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
