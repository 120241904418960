import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {LoginService} from './login.service';
import {UserService} from '../../shared/service/user.service';
import {AlertService} from '../../shared/service/alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  passwordChangeForm: FormGroup;

  submitted: boolean;

  requirePasswordChange: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private alertService: AlertService
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });

    this.passwordChangeForm = this.fb.group({
      username: new FormControl('', Validators.required),
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      newPasswordVerify: new FormControl('')
    });
  }

  onSubmit() {
    if (!this.submitted) {
      this.submitted = true;
      this.loginService.login(this.form.value).subscribe((response) => {
        this.submitted = false;
        if (response && response.isSuccess === 'Y') {
          this.alertService.onSuccess().then(() => {
            let routerState = UserService.getRouterState();
            if (!routerState) {
              routerState = '/Home/dashboard';
            }
            this.router.navigateByUrl(routerState).then();
          });
        } else {
          if (response && response.errors && response.errors[0].includes('[PWDCHG]')) {
            this.passwordChangeForm.controls.username.setValue(this.form.controls.username.value);
            this.passwordChangeForm.controls.oldPassword.setValue(this.form.controls.password.value);
            this.requirePasswordChange = true;
          } else {
            this.alertService.onError('UsernameOrPasswordIncorrect').then();
          }
        }
      });
    }
  }

  onPasswordChange() {
    this.passwordChangeForm.controls.newPasswordVerify.setValue(this.passwordChangeForm.controls.newPassword.value);
    this.loginService.changePassword(this.passwordChangeForm.value).subscribe(() => {
      this.form.controls.password.setValue(this.passwordChangeForm.controls.newPassword.value);
      this.onSubmit();
      this.requirePasswordChange = false;
    });
  }

}
