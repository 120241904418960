import {Component, OnDestroy, OnInit} from '@angular/core';

import {Col} from '../../../shared/components/col.model';

import {BreadcrumbService} from '../../../layout/breadcrumb/breadcrumb.service';

import {ItemsService} from './items.service';

@Component({
  selector: 'app-geos',
  templateUrl: './items.component.html'
})
export class ItemsComponent implements OnInit, OnDestroy {

  cols: Array<Col>;
  items: Array<any>;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private itemsService: ItemsService
  ) {
  }

  ngOnInit() {
    this.breadcrumbService.setRouterLinkCreate('/Admin/geos/create');

    this.cols = [
      new Col('geoId', 'pseudoId', 300, 'contains'),
      new Col('geoName', 'Geo', 'auto', 'contains')
    ];

    this.items = new Array<any>();
    this.itemsService.getList('VNM', 'GEOT_PROVINCE').subscribe(({listData}) => {
      this.items = listData;
    });
  }

  ngOnDestroy(): void {
    this.breadcrumbService.setRouterLinkCreate(null);
  }

}
