import {Component, Input, OnInit} from '@angular/core';

import {TikiService} from '../../../entities/service/tiki.service';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html'
})
export class BarcodeComponent implements OnInit {

  @Input() code: string;
  @Input() width: number;
  @Input() height: number;
  @Input() font: number;
  image: string;

  constructor(private tikiService: TikiService) {
  }

  ngOnInit() {
    if (this.code) {
      this.tikiService.getBarcode(this.code).subscribe(({response}) => {
        if (response) {
          this.image = response;
        }
      });
    }
  }

}
