import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {ItemsService as GeosService} from '../../../admin/geos';
import {ItemsService} from '../items.service';
import {AlertService} from '../../../../shared/service/alert.service';
import {AuthService} from '../../../../shared/service/auth.service';

@Component({
  selector: 'app-dialog-crup-customer-address',
  templateUrl: './dialog-crup-customer-address.component.html'
})
export class DialogCrupCustomerAddressComponent implements OnInit {

  @Input() onComplete: any;
  @Input() partyId: any;
  @Input() postalAddress: any;
  @Input() telecomNumberList: Array<any>;

  display: boolean;

  stateProvinces: Array<any>;
  counties: Array<any>;

  form: FormGroup;
  submitted: boolean;

  constructor(
    private fb: FormBuilder,
    private geosService: GeosService,
    private itemsService: ItemsService,
    private alertService: AlertService,
    public authService: AuthService
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      postalContactMechId: new FormControl(''),
      postalContactMechPurposeId: new FormControl('PostalShippingDest'),
      toName: new FormControl('', Validators.required),
      telecomContactMechId: new FormControl(''),
      contactNumber: new FormControl('', Validators.required),
      address1: new FormControl('', Validators.required),
      countyGeoId: new FormControl(''),
      stateProvinceGeoId: new FormControl(''),
      countryGeoId: new FormControl('VNM'),
    });

    if (this.postalAddress) {
      this.form.patchValue(this.postalAddress);
    }

    this.geosService.getList('VNM', 'GEOT_PROVINCE').subscribe(({listData: stateProvinces}) => {
      if (stateProvinces && stateProvinces.length > 0) {
        this.form.controls.stateProvinceGeoId.patchValue(stateProvinces[0]);
        this.loadCounties();
      }
      this.stateProvinces = stateProvinces;
    });
  }

  initForm() {
    this.form.reset();
    if (this.stateProvinces && this.stateProvinces.length > 0) {
      this.form.controls.stateProvinceGeoId.patchValue(this.stateProvinces[0]);
      this.loadCounties();
    }
  }

  showDialog() {
    if (this.postalAddress) {
      this.form.patchValue(this.postalAddress);
      if (this.postalAddress.stateProvinceGeoId) {
        this.geosService.getList(this.postalAddress.stateProvinceGeoId, 'GEOT_COUNTY').subscribe(({listData: counties}) => {
          this.counties = counties;
          if (counties && counties.length > 0) {
            if (this.postalAddress.countyGeoId) {
              this.form.controls.countyGeoId.patchValue({
                toGeoId: this.postalAddress.countyGeoId
              });
            } else {
              this.form.controls.countyGeoId.patchValue(counties[0]);
            }
          }
        });
        this.form.controls.stateProvinceGeoId.patchValue({
          toGeoId: this.postalAddress.stateProvinceGeoId
        });
      }
    }
    this.display = true;
  }

  loadCounties() {
    this.counties = new Array<any>();
    if (this.form.controls.stateProvinceGeoId.value && this.form.controls.stateProvinceGeoId.value.toGeoId) {
      this.geosService.getList(this.form.controls.stateProvinceGeoId.value.toGeoId, 'GEOT_COUNTY').subscribe(({listData: counties}) => {
        this.counties = counties;
        if (counties && counties.length > 0) {
          this.form.controls.countyGeoId.patchValue(counties[0]);
        }
      });
    }
  }

  onDropdownContactNumberChange() {
    if (this.form.controls.telecomContactMechId.value && this.form.controls.telecomContactMechId.value.telecomContactMechId) {
      this.form.controls.contactNumber.reset();
      this.form.controls.contactNumber.clearValidators();
      this.form.controls.contactNumber.disable();
    } else {
      this.form.controls.contactNumber.setValidators(Validators.required);
      this.form.controls.contactNumber.enable();
    }
  }

  onSubmit() {
    if (!this.submitted) {
      this.submitted = true;

      if (this.form.controls.telecomContactMechId.value && this.form.controls.telecomContactMechId.value.telecomContactMechId) {
        this.form.controls.contactNumber.setValue(this.form.controls.telecomContactMechId.value.contactNumber);
        this.form.controls.telecomContactMechId.setValue(this.form.controls.telecomContactMechId.value.telecomContactMechId);
      }

      if (this.form.controls.postalContactMechId.value) {
        this.itemsService.updateContactInfos(this.partyId, this.form.controls.postalContactMechId.value, Object.assign(this.form.value, {
          stateProvinceGeoId: this.form.controls.stateProvinceGeoId.value.toGeoId,
          countyGeoId: this.form.controls.countyGeoId.value.toGeoId
        })).subscribe(({postalContactMechId}) => {
          this.submitted = false;
          if (postalContactMechId) {
            this.onSuccess();

            this.initForm();
          } else {
            this.onError();
          }
        }, () => {
          this.submitted = false;
        });
      } else {
        this.itemsService.createContactInfos(this.partyId, this.form.value).subscribe(({postalContactMechId}) => {
          this.submitted = false;
          if (postalContactMechId) {
            this.onSuccess();

            this.initForm();
          } else {
            this.onError();
          }
        }, () => {
          this.submitted = false;
        });
      }
    }
  }

  private onSuccess() {
    this.alertService.onSuccess().then(() => {
      this.display = false;
      this.onComplete();
    });
  }

  private onError() {
    this.alertService.onError().then();
  }
}
