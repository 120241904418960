import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  private readonly url = 'mag-erp-admin/sales-orders';

  constructor(private http: HttpClient) {
  }

  getList(lazyLoadEvent: string): Observable<any> {
    return this.http.get(`${this.url}?lazyLoadEvent=${lazyLoadEvent}`);
  }

  getTentativeOrders(productStoreId: string, facilityId: string, customerPartyId: string): Observable<any> {
    return this.http.get(`/mag-erp-admin/sales-tentative-orders`, {params: {productStoreId, facilityId, customerPartyId}});
  }

  create(item: any): Observable<any> {
    return this.http.post(`${this.url}`, item);
  }

  getSalesOrderProfit(orderId: string): Observable<any> {
    return this.http.get(`sales-report/sales-order-profit?orderId=${orderId}`);
  }

  getProductSold(customerPartyId: string, productId: string): Observable<any> {
    return this.http.get(`${this.url}/product-sold?customerPartyId=${customerPartyId}&productId=${productId}`);
  }

}
