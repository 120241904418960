import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {ItemsService} from './items.service';
import {ServiceJobService} from '../../service/service-job.service';
import {AlertService} from '../../../shared/service/alert.service';

@Component({
  selector: 'app-product-store-crup',
  templateUrl: './item-crup.component.html'
})
export class ItemCrupComponent implements OnInit {

  form: FormGroup;
  organizations: Array<any>;

  submitted: boolean;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private itemsService: ItemsService,
    private serviceJobService: ServiceJobService,
    private alertService: AlertService
  ) {
  }

  ngOnInit() {
    this.organizations = [
      {organizationPartyId: 'SN247_ORG', organizationParty: 'SN247_ORG'},
      {organizationPartyId: 'TIKI_ORG', organizationParty: 'TIKI_ORG'},
      {organizationPartyId: 'SENDO_ORG', organizationParty: 'SENDO_ORG'}
    ];
    this.form = this.fb.group({
      productStoreId: new FormControl('', Validators.required),
      storeName: new FormControl('', Validators.required),
      organizationPartyId: new FormControl('', Validators.required),
      includeFacility: new FormControl(false),
      systemMessageRemoteId: new FormControl(''),
      username: new FormControl(''),
      password: new FormControl(''),
      syncOrderJobName: new FormControl(''),
      cronExpressionSync: new FormControl(''),
      confirmOrderJobName: new FormControl(''),
      cronExpressionConfirm: new FormControl('')
    });

    this.form.controls.organizationPartyId.setValue(this.organizations[0]);

    this.route.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.itemsService.get(params.get('id')).subscribe((response) => {
          if (response.organizationPartyId) {
            response.organizationPartyId = {organizationPartyId: response.organizationPartyId};
          }
          this.form.patchValue(response);

          if (response.syncOrderJobName) {
            this.serviceJobService.get(response.syncOrderJobName).subscribe(({cronExpression}) => {
              this.form.controls.cronExpressionSync.setValue(cronExpression);
            });
          }
          if (response.confirmOrderJobName) {
            this.serviceJobService.get(response.confirmOrderJobName).subscribe(({cronExpression}) => {
              this.form.controls.cronExpressionConfirm.setValue(cronExpression);
            });
          }
        });
        this.itemsService.getSystemMessageRemote(params.get('id')).subscribe((response) => {
          this.form.patchValue(response);
        });
      }
    });
  }

  onSubmit() {
    if (!this.submitted) {
      this.submitted = true;

      this.form.controls.organizationPartyId.setValue(this.form.controls.organizationPartyId.value.organizationPartyId);

      if (this.form.controls.productStoreId.value) {
        this.itemsService.update(this.form.controls.productStoreId.value, this.form.value).subscribe((response) => {
          if (response.productStoreId) {
            this.onSuccess();
          } else {
            this.onError();
          }
        });
      } else {
        this.itemsService.create(this.form.value).subscribe((response) => {
          if (response.productStoreId) {
            this.onSuccess();
          } else {
            this.onError();
          }
        });
      }
    }
  }

  private onSuccess() {
    this.alertService.onSuccess().then(() => {
      this.router.navigateByUrl('/Admin/product-stores').then();
    });
  }

  private onError() {
    this.submitted = false;
    this.alertService.onError().then();
  }

}
