import {Component, OnInit} from '@angular/core';

import {environment} from '../../../environments/environment';

import {ModuleMenuModel as ModuleMenu} from '../../shared/module-menu/module-menu.model';

import {LeftSidebarService} from './left-sidebar.service';
import {AuthService} from '../../shared/service/auth.service';
import {UserService} from '../../shared/service/user.service';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html'
})
export class LeftSidebarComponent implements OnInit {

  module: ModuleMenu;

  baseHref = environment.baseHref;

  permissions: Array<any>;

  constructor(
    private leftSidebarService: LeftSidebarService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.leftSidebarService.getModule().subscribe((module) => {
      this.module = module;
    });
    this.permissions = this.authService.getAuthorization();
  }

  routerLinkHasAuthorization(routerLink): boolean {
    if (UserService.getUsername() === 'john.doe') {
      return true;
    } else {
      let has = false;
      if (this.permissions && this.permissions.length > 0 && routerLink) {
        for (const permission of this.permissions) {
          if (permission && permission.path === routerLink && permission.artifacts) {
            for (const artifact of permission.artifacts) {
              if (artifact && artifact.permissions && artifact.permissions.length > 0) {
                has = true;
                break;
              }
            }
            break;
          }
        }
      }
      return has;
    }
  }

  menuHasAuthorization(menu): boolean {
    if (UserService.getUsername() === 'john.doe') {
      return true;
    } else {
      let has = false;
      if (this.permissions && this.permissions.length > 0 && menu) {
        if (menu.routerLink) {
          has = this.routerLinkHasAuthorization(menu.routerLink);
        } else if (menu.children) {
          for (const child of menu.children) {
            if (child && child.routerLink && this.routerLinkHasAuthorization(child.routerLink)) {
              has = true;
              break;
            }
          }
        }
      }
      return has;
    }
  }

}
