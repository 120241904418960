import {Injectable} from '@angular/core';

@Injectable({
  providedIn: `root`
})
export class UserService {

  static readonly app = 'doremi';

  static setUsername(username: string) {
    localStorage.setItem(`${this.app}_username`, username);
  }

  static getUsername(): string {
    return localStorage.getItem(`${this.app}_username`);
  }

  static setRouterState(routerState: string) {
    localStorage.setItem(`${this.app}_routerLink_${this.getUsername()}`, routerState);
  }

  static getRouterState(): string {
    return localStorage.getItem(`${this.app}_routerLink_${this.getUsername()}`);
  }

  static setFacilityId(facilityId: string) {
    localStorage.setItem(`${this.app}_facilityId_${this.getUsername()}`, facilityId);
  }

  static getFacilityId(): string {
    return localStorage.getItem(`${this.app}_facilityId_${this.getUsername()}`);
  }

  static setProductStoreId(productStoreId: string) {
    localStorage.setItem(`${this.app}_productStoreId_${this.getUsername()}`, productStoreId);
  }

  static getProductStoreId(): string {
    return localStorage.getItem(`${this.app}_productStoreId_${this.getUsername()}`);
  }

  static setPrintMinCode(printMinCode: boolean) {
    localStorage.setItem(`${this.app}_printMinCode_${this.getUsername()}`, printMinCode ? `Y` : `N`);
  }

  static getPrintMinCode(): boolean {
    return localStorage.getItem(`${this.app}_printMinCode_${this.getUsername()}`) === 'Y';
  }

  static setMergePage(mergePage: boolean) {
    localStorage.setItem(`${this.app}_mergePage_${this.getUsername()}`, mergePage ? `Y` : `N`);
  }

  static getMergePage(): boolean {
    return localStorage.getItem(`${this.app}_mergePage_${this.getUsername()}`) === 'Y';
  }

  static setTikiAutoShip(tikiAutoShip: boolean) {
    localStorage.setItem(`${this.app}_tikiAutoShip_${this.getUsername()}`, tikiAutoShip ? `Y` : `N`);
  }

  static getTikiAutoShip(): boolean {
    return localStorage.getItem(`${this.app}_tikiAutoShip_${this.getUsername()}`) === `Y`;
  }

  static setLazadaAutoShip(lazadaAutoShip: boolean) {
    localStorage.setItem(`${this.app}_lazadaAutoShip_${this.getUsername()}`, lazadaAutoShip ? `Y` : `N`);
  }

  static getLazadaAutoShip(): boolean {
    return localStorage.getItem(`${this.app}_lazadaAutoShip_${this.getUsername()}`) === `Y`;
  }

  static setShopeeAutoShip(shopeeAutoShip: boolean) {
    localStorage.setItem(`${this.app}_shopeeAutoShip_${this.getUsername()}`, shopeeAutoShip ? `Y` : `N`);
  }

  static getShopeeAutoShip(): boolean {
    return localStorage.getItem(`${this.app}_shopeeAutoShip_${this.getUsername()}`) === `Y`;
  }

  static setShopeeShippingType(shippingType: string) {
    localStorage.setItem(`${this.app}_shopeeShippingType_${this.getUsername()}`, shippingType);
  }

  static getShopeeShippingType(): string {
    return localStorage.getItem(`${this.app}_shopeeShippingType_${this.getUsername()}`);
  }

  static setTikiInventory(productStoreId: string, shippingType: string) {
    localStorage.setItem(`${this.app}_tikiInventory_${this.getUsername()}_${productStoreId}`, shippingType);
  }

  static getTikiInventory(productStoreId: string): string {
    return localStorage.getItem(`${this.app}_tikiInventory_${this.getUsername()}_${productStoreId}`);
  }

}
