import {Component, Input, OnInit} from '@angular/core';

import {ItemsService as CustomerService} from '../../../entities/sales/customers';

@Component({
  selector: 'app-search-customer',
  templateUrl: './search-customer.component.html'
})
export class SearchCustomerComponent implements OnInit {

  @Input() disabled: boolean;
  @Input() onSelect: any;
  item: any;
  items: Array<any>;

  submitted: boolean;

  constructor(private customerService: CustomerService) {
  }

  ngOnInit() {
  }

  search(event) {
    this.customerService.search({
      term: event.query,
    }).subscribe((response) => {
      this.items = response.listData;
    });
  }

  select() {
    this.onSelect(this.item);
    this.submitted = false;
    setTimeout(() => {
      this.submitted = true;
    }, 100);
  }

  clean() {
    if (this.submitted) {
      this.item = null;
    }
  }
}
