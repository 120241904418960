import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {formatDate} from '@angular/common';

import {Observable} from 'rxjs';

import * as md5 from 'md5';

@Injectable({
  providedIn: 'root'
})
export class TikiService {

  private readonly url = 'mag-tiki/remote';

  constructor(private http: HttpClient) {
  }

  get(productStoreId: string, parameters: any): Observable<any> {
    return this.http.post(`${this.url}/get`, {productStoreId, parameters});
  }

  put(productStoreId: string, parameters: any): Observable<any> {
    return this.http.post(`${this.url}/put`, {productStoreId, parameters});
  }

  post(productStoreId: string, parameters: any): Observable<any> {
    return this.http.post(`${this.url}/post`, {productStoreId, parameters});
  }

  request(productStoreId: string, config: any): Observable<any> {
    return this.http.post(`${this.url}/request`, {productStoreId, config: Object.assign(config, {key: this._getKey()})});
  }

  _getKey() {
    return md5(`cuong-yeu-phuong-${formatDate(new Date(), 'dd-MM-yyyy', 'en-US')}`);
  }

  getUser(productStoreId: string): Observable<any> {
    return this.http.get(`mag-tiki/user?productStoreId=${productStoreId}`);
  }

  getBarcode(code: string): Observable<any> {
    return this.http.get(`mag-tiki/barcode?code=${code}`);
  }

  findOrderHeader(productStoreId: string, externalId: string): Observable<any> {
    return this.http.get(`mag-tiki/orders/${productStoreId}/OrderHeader/${externalId}`);
  }

  syncOrderHeader(productStoreId: string, externalId: string): Observable<any> {
    return this.http.post(`mag-tiki/orders/${productStoreId}/OrderHeader/${externalId}`, {});
  }

  setTrackingNumber(productStoreId: string, item: any): Observable<any> {
    return this.http.post(`mag-tiki/setTrackingNumber/${productStoreId}`, item);
  }

  findByTrackingNumber(trackingNumber: string): Observable<any> {
    return this.http.get(`mag-tiki/findByTrackingNumber?trackingNumber=${trackingNumber}`);
  }

}
